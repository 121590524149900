import React from 'react';
import ar from '../../locale';

export default class inputToolBar extends React.Component {

  constructor(props, context){
    super(props);
    this.state = {
      value: null,
    };
  }

  onKey = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      this.onSend();
      e.preventDefault();
    } else {
      this.state.value = e.target.value;
    }
    return false;
  }

  onSend = () => {
    let {value} = this.state;
    value = value ? value.trim() : null;
    if (value) {
      this.input.value='';
      this.props.onSend([{ text: value }], true);
    }
  }

  render() {
    return (
      <div className="input-group form-group flex-row m-0">
        <div className="input-group-prepend">
          <button className="btn btn-info" type="button" onClick={this.props.onPressActionButton}><i className="fa fa-paperclip"></i></button>
        </div>
        <input
          type="text"
          className="form-control border-0"
          placeholder={ar.type_here}
          ref={el => this.input = el}
          onKeyUp={this.onKey} 
          />
          <div className="input-group-append">
            <button className="btn btn-primary-inverse" type="button" onClick={this.onSend}>{ar.send}</button>
          </div>
      </div>
    );
  }
}