import React from 'react'
import firebase from '../../lib/firebase';
import { AuthContext } from '../../lib/AuthContext';
import ParentComponent from '../../lib/ParentComponent';

export default class SlideshowIndividual extends ParentComponent{
  static contextType = AuthContext;
  constructor(props, context) {
      super(props, context);
      this.state = {
          user: context.user,
          slide: {},
      };
      this.slideshowRef = firebase.database().ref('slideshow/'+this.props.match.params.key);
  }

  componentDidMount() {
    this.slideshowRef.on('value', response => {
      this.state.slide = (response.val());
      this.updateState();
    });
  }

  componentWillUnmount() {
      this.slideshowRef.off('value');
  }

  updateState = () => {
    this.setState(prev => {
        return {...prev}
    });
  };

  renderSlide = () => {
    let {slide} = this.state;
    return(
        <div key={slide.key} className="rounded position-relative o-v" style={{backgroundImage: 'url('+slide.image+')'}}>
          <div className="w-100 h-100 p-5">
            <h3 className="mb-0">{slide.title}</h3>
            <h6 className="m-0">{slide.subtitle}</h6>
          </div>
        </div>
    );
  };

  render() {
    return (
      <div className="site-content">
		  <div className="container">
            {this.renderSlide()}
            <p className="mt-4">
                {this.state.slide.content}
            </p>
          </div>
      </div>
    );
  }
}