import React from 'react'
import Utils from '../../lib/Utils';
import firebase, {auth} from '../../lib/firebase';
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Loading from '../../lib/Loading';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css'
import DatePicker from "react-datepicker";
import ReactSlider from 'react-slider';
import "react-datepicker/dist/react-datepicker.css";
import CustomDateInput from '../../lib/CustomDateInput';
import Cropper from 'react-easy-crop';
import ar from '../../locale';
import moment from 'moment';
import 'moment/locale/ar';
import { toast } from 'react-toastify';
moment.locale('ar');

export default class TermsAndConditions extends ParentComponent{
  static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            loading: false,
            social: {},
        };
        this.socialRef = firebase.database().ref('/social');
    }

    componentDidMount() {
        this.socialRef.on('value', response => {
            this.state.social = response.val();
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.socialRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    showError = msg => {
        toast.error(msg, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    showSuccess = msg => {
        toast.success(msg, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    showLoading = show => {
        this.state.loading = show;
        this.updateState();
    };

    render() {
        let {social} = this.state;
        return (
        <div>
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <h1 className="page-heading__title"><span className="highlight">{ar.terms_conditions}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-content">
                <div className="container rtl">
                    <div className="row">
                        <div className="col-12 no-pmb" dangerouslySetInnerHTML={{ __html: social.terms }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
};