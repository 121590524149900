/* contains the arabic transalation text */
const ar = {
    voting_closed: 'التصويت مغلق',
    terms_conditions_des: 'من خلال تسجيل الدخول ، فإنك توافق على الشروط والأحكام',
    terms_conditions: 'الأحكام والشروط',
    resend: 'إعادة إرسال',
    home: 'الصفحة الرئيسية',
    chatclubs: 'شات الاندية',
    you_r_trainer: 'أنت المدرب',
    egy_var: 'تصويت ال Var',
    academic_talents: 'اكاديميات',
    womens_ball: 'الكرة النسائية',
    egy_coach_awards: 'جوائز المدرب المصرى',
    my_hobby: 'موهبتى',
    prize: 'جائزة',
    var_poll: 'تصويت ال Var',

    email: 'البريد الإلكتروني',
    password: 'كلمه السر',
    sign_in: 'تسجيل الدخول',
    sign_out: 'تسجيل الخروج',
    invalid_sign_in: 'البريد الإلكتروني او كلمة المرور غير صالحة',
    sign_in_cancelled: 'تم إلغاء تسجيل الدخول بواسطتك',
    sign_in_under_progress: 'جاري تسجيل الدخول',
    play_services_unavailable: 'خدمات جوجل بلاي غير متوفرة',
    sign_in_using_google: 'تسجيل الدخول بحساب جوجل',
    sign_in_using_apple: 'تسجيل الدخول بحساب ابل',
    sign_in_using_facebook: 'تسجيل الدخول بحساب الفيس بوك',
    forgot_password: 'هل نسيت كلمة المرور',
    sign_up_now: 'سجل الان',
    more: 'المزيد',
    featured_hobby: 'افضل المواهب',
    featured_formations: 'افضل الخطط المقترحة',
    
    section_empty: 'هذا القسم فارغ حاليا',
    edit_profile_pic: 'تعديل صورة الملف الشخصي',
    name: 'الاسم',
    club: 'النادي',
    notifications_settings: 'إعدادات الإشعارات',
    noti_club_match: 'يخطر في مباريات النادي',
    noti_post_activity: 'يخطر على وظيفة آخر / الفيديو',
    noti_app_updates: 'يخطر على تحديثات التطبيق',
    save: 'حفظ',
    operation_completed: 'تم حفظ  بنجاح',

    hello: 'مرحبا',
    enter_your_email: 'أدخل بريدًا إلكترونيًا صالحًا',
    reset: 'إعادة التعيين',
    reset_success_msg:
        'تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني',
    already_have_an_account: 'هل لديك حساب ؟',

    additional_details: 'تفاصيل اضافية',
    enter_using_cc: 'ادخل رقم الهاتف الخاص بك',
    not_you: 'ليس انت ؟',
    enter_valid_number: 'أدخل رقم موبايل صالح',
    send_otp: 'إرسال',
    enter_valid_otp: 'ادخل رمز التفعيل',
    verify_otp: 'التحقق',
    otp_sent_msg: 'تم إرسال كلمة المرور لمرة واحدة إلى هاتفك المحمول',
    phone_ver_cancelled: 'تم إلغاء التحقق من رقم الهاتف',
    otp_mismatch:
        'الرمز الذي تم إدخاله لا يتطابق مع الرمز المرسل على الهاتف المحمول',
    phonenumber_in_use: 'رقم الهاتف قيد الاستخدام بالفعل من قبل مستخدم آخر',

    male: 'ذكر',
    female: 'انثى',
    others: 'الآخرين',
    back: 'الى الخلف',
    birthdate: 'تاريخ الميلاد',
    gender: 'النوع',
    select_club: 'اختر نادي',
    take_from_camera: 'اختار من الكاميرا',
    choose_from_gallery: 'اختار من المعرض',
    cancel: 'إلغاء',
    select_option: 'حدد خيار',
    enter_name: 'أدخل أسمك',
    enter_valid_name: 'الرجاء إدخال اسم صالح',
    enter_valid_email: 'الرجاء إدخال بريد إلكتروني صحيح',
    enter_valid_password: 'الرجاء إدخال كلمة مرور صالحة',
    enter_valid_birthdate: 'الرجاء إدخال تاريخ ميلاد صالح',
    enter_valid_gender: 'الرجاء إدخال جنس صالح',
    enter_valid_club: 'الرجاء إدخال نادي صالح',
    email_already_in_use:
        'حساب البريد الإلكتروني قيد الاستخدام بالفعل مع حساب آخر',

    chatrooms: 'شات الاندية',
    success_joined_room: 'تم الانضمام إلى غرفة الدردشة بنجاح',
    success_left_room: 'تم مغادرة  غرفة الدردشة بنجاح',
    empty_chat_msg_1: 'لم تنضم إلى أي غرف دردشة حتى الآن',
    empty_chat_msg_2: 'انضم الآن الي غرف الدردشة المتاحة',
    room_debarred: 'لقد تم حذرك من الانضمام إلى هذه الغرفة',
    join: 'انضم',
    subscribers: 'مشتركين',
    leave: 'غادر',
    available: 'متاح',
    my_chatrooms: 'غرف الدردشة',
    adhere_group_policies: 'الالتزام بسياسات و قواعد المجموعة!',
    agree: 'يوافق على',

    feedback_recieved: 'تم الابلاغ عن هذا المحتوي',
    flag_inappropriate: 'ابلاغ عن محتوي غير لائق',
    copy_message: 'نسخ الرسالة',
    type_here: 'اكتب رسالتك هنا',
    load_earlier: 'تحميل الرسائل السابقة',

    feminine_splash_button: 'ابدأ الآن',
    feminine_splash_heading: 'الكرة النسائية',
    feminine_splash_test:
        'اكتشف كل شيء عن كرة القدم النسائية في مصر من خلال مقاطع الفيديو العصرية والأخبار والتدريب التي ترغب في المشاركة فيها',

    videos: 'فيديوهات',
    news: 'أخبار',
    training: 'دورات تدريبية',
    likes: 'اعجاب',
    share_via: 'شارك عبر',
    featured: 'افضل المواهب',
    featured_tag_text_for_formation: 'الأكثر تفاعلا',

    academic_splash_button: 'ابدأ الآن',
    academic_splash_heading: 'اكاديميات ومواهب',
    academic_splash_test:
        'اذا كنت تبحث عن اكاديميات التدريب في مصر وافضل الدورات التدريبية',
    subscribed: 'مشترك',
    cancel_subscription: 'إلغاء الاشتراك',
    confirm_subscription: 'تأكيد الاشتراك',
    confirmation_text: 'هل أنت متأكد أنك تريد الاشتراك في هذه الدورة؟',
    confirmed_text: 'لقد اشتركت بنجاح في هذه الدورة التدريبية',
    keep_browsing: 'الاستمرار بالتصفح',
    subscribe_now: 'إشترك الآن',

    hobby_splash_button: 'ابدأ الآن',
    hobby_splash_heading: 'موهبتي',
    hobby_splash_test:
        'اكتشف الآن مواهب حقيقية، بتصويتك لهذه المواهب فانت بذلك تدعمهم',
    upload_video: 'رفع فيديو',
    upload_video_heading: 'قم بتحميل فيديو لموهبتك',
    upload_video_subheading: 'وهانوصله لكبرى الأندية المصرية',
    empty_comments: 'لم يتم اضافة تعليق بعد',
    comments: 'تعليقات',
    enter_comments: 'أدخل التعليق هنا',

    upload_consent_heading: 'قم برفع فيديو يعرض موهبتك',
    upload_consent_des:
        'تعرض الفيديوهات الفائزة بأعلى تصويت الى كبرى أندية كرة القدم المصرية',
    fifty_egp_pounds: '50 جنيها مصريا',
    pay_now: 'ادفع الآن',
    card_number: 'رقم البطاقة',
    cvc: 'CVC',
    exp: 'EXP',
    holder_name: 'اسم حامل البطاقة',
    confirm: 'تاكيد',

    provide_country_age: 'يرجى ادخال بلد وسن صالح',
    video_too_large: 'الفيديو كبير جدًا ، جرب أقل من 100 ميجابايت',
    video_first: 'يرجى اختيار الفيديو أولا',
    tap_to_select: 'انقر لتحديد ملف',
    country: 'بلد',
    age: 'عمر',
    upload_now: 'ارفع الآن',
    waiting_for_upload: 'في انتظار التحميل حتى النهاية',
    upload_successful: 'تم رفع الملف بنجاح',
    you_can_see: 'يمكنك مشاهدة مقاطع الفيديو الخاصة بك في قسم المواهب',
    continue: 'استمر',

    matches: 'المباريات',
    rankings: 'الترتيب',
    p: 'ن',
    r: 'ت',
    w: 'ف',
    l: 'خ',
    d: 'ت',
    club_name: 'اسم النادي',

    contact_us: 'اتصل بنا',
    enter_subject: 'أدخل الموضوع',
    enter_message: 'أدخل رسالتك',
    message_sent: 'تم ارسال الرسالة بنجاح',
    send_now: 'ارسل الان',
    notifications: 'الاشعارات',

    egy_var_splash_heading: 'تصويت Egyptian Var',
    egy_var_splash_test:
        'شارك في حصاد جوائز العام من خلال تطبيق المدرب المصري المميز ، واختر خيارك الأمثل وسيجمع التطبيق الإجابات على ملتقي الجوائز',
    egy_var_splash_button: 'ابدأ الآن',

    egy_coach_splash_heading: 'جوائز Egyptian Coach',
    egy_coach_splash_test:
        'شارك الآن في حصاد جوائز العام من خلال تطبيق ايجبشن كوتش المتميز',
    egy_coach_splash_button: 'ابدأ الآن',

    egy_coach_heading_ar: 'أنت المدرب',
    egy_coach_heading: 'Egyptian Coach',
    egy_coach_heading_test:
        'في ايجيبشن كوتش ستكون انت المدرب، فيمكنك وضع خطة فريقك المفضل',

    change_lineup: 'التشكيل الخاص بيك',
    expectations: 'توقعاتك',
    suggested_formations: 'تشكيلات مقترحة',
    save_formation: 'حفظ التشكيل',
    saved: 'تم الحفظ',
    edit: 'تعديل',
    delete: 'حذف',



    sports_and_talent: 'أنت المدرب',
    join_our_team: 'انضم لفريقنا',
    copyright: 'حقوق النشر',
    all_rights_reserved: 'كل الحقوق محفوظة',
    your_account: 'الحساب الخاص بك',
    language: 'لغة',
    arabic: 'عربى',
    welcome: 'أهلا بك',
    my_club: 'النادي',
    user: 'المستخدم',
    clubs: 'الأندية',
    rankings_matches: 'التصنيف و المباريات',
    login_to_use: 'الرجاء تسجيل الدخول لاستخدام هذه الميزة',
    login_to_access: 'يرجى تسجيل الدخول للوصول إلى القسم',
    share: 'سهم',
    ok: 'حسنا',
    flag_msg_conrimation: 'هل ترغب في الإبلاغ عن هذه الرسالة باعتبارها غير لائقة؟',
    delete_msg_conrimation: 'هل أنت متأكد أنك تريد حذف هذه الرسالة ؟',
    send: 'إرسال',
    contact_form: 'نموذج الاتصال',
    contact_form_header: 'أرسل لنا رسالة',
    contact_form_content: 'إذا كان لديك أي أسئلة ، فلا تتردد في ترك لي رسالة. سنعود إليك في أقرب وقت ممكن ، وهذا وعد.',
    max_11_players: 'مجموع 11 لاعبين اللازمة',
    change_linup_how_to: 'اسحب اللاعبين من الأسفل إلى الحقل لوضعهم ، انقر فوق حفظ للتحميل.',
    know_more: 'تعرف أكثر',
    upcoming_current_matches: 'المباريات القادمة / الحالية',
    no_matches: 'لا توجد مباريات القادمة',
    years: 'سنوات',
    enter_valid_details: 'الرجاء إدخال تفاصيل صالحة',
    login_or_register: 'تسجيل الدخول أو التسجيل',
    login_with_credentials: 'يرجى تسجيل الدخول باستخدام بيانات الاعتماد الخاصة بك',
    login_to_your_account: 'تسجيل الدخول إلى حسابك',
    enter_your_password: 'ادخل رقمك السري',
    oops_page_not_found: 'الصفحة غير موجودة',
    not_found_content: 'تم نقل الصفحة التي تبحث عنها أو لم تعد موجودة ، إذا أردت ، يمكنك العودة إلى صفحتنا الرئيسية. إذا استمرت المشكلة ، فيرجى إرسال بريد إلكتروني إلينا',
    return_to_home: 'العودة الى الرئيسية',
    invalid_contact_description: 'رقم الاتصال في تنسيق غير صالح ، أدخل باستخدام رمز البلد على سبيل المثال: +20123456789',
    profile_updated_successfully: 'تم تحديث الملف الشخصي بنجاح',
    kickoff: 'انطلاق',
    away: 'بعيدا',
    see_preview: 'يمكنك أن ترى المعاينة هنا',
};
export default ar;
