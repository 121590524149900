import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import firebase from '../../lib/firebase';
import Utils from '../../lib/Utils';
import ar from '../../locale';

export default class ClubRankings extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            clubs: [],
            pageloading: true,
        };
        this.clubRef = firebase.database().ref('clubs/');
    }

    componentDidMount() {
        this.clubRef.orderByChild('points').on('value', response => {
            this.state.clubs = Utils.convertToArrayWithOrderKeyAsc(
                response.val(),
                'order',
            );
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.clubRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    renderRankings = (club, index) => {
        return (
            <tr key={index+''}>
                <td className="team-standings__pos">{index +1}</td>
                <td className="team-standings__team">
                    <div className="team-meta text-right">
                        <figure className="team-meta__logo">
                            <img src={club.avatar} className="rounded-circle" alt="club" />
                        </figure>
                        <div className="team-meta__info">
                            <h6 className="team-meta__name text-right pr-1 pl-1">{club.name}</h6>
                        </div>
                    </div>
                </td>
                <td className="team-standings__win">{club.points || 0}</td>
                <td className="team-standings__lose">{club.wins || 0}</td>
                <td className="team-standings__ties">{club.lost || 0}</td>
                <td className="team-standings__pct">{club.draw || 0}</td>
            </tr>
        );
    };

    render() {
        let {hidebanner} = this.props;
       return (
           <>
            {!hidebanner && (
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                {this.state.user && (
                                    <h1 className="page-heading__title"><span className="highlight">{ar.my_club}</span></h1>
                                )}
                                {!this.state.user && (
                                    <h1 className="page-heading__title"><span className="highlight">{ar.clubs}</span></h1>
                                )}
                                <h4 className="mt-1 text-center">{ar.rankings}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="site-content">
                <div className="container">
                    {this.state.pageloading && (
                        <div className="text-center">
                            <span className="spinner-border text-success" role="status"></span>
                        </div>
                    )}
                    {!this.state.pageloading && (
                    <div className="card card--has-table rtl">
                        <div className="card__content">
                            <div className="table-responsive">
                                <table className="table table-hover table-standings table-standings--full table-standings--full-football">
                                    <thead>
                                        <tr>
                                            <th className="team-standings__pos">{ar.r}</th>
                                            <th className="team-standings__team text-right">{ar.club_name}</th>
                                            <th className="team-standings__pct">{ar.p}</th>
                                            <th className="team-standings__win">{ar.w}</th>
                                            <th className="team-standings__lose">{ar.l}</th>
                                            <th className="team-standings__ties">{ar.d}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.clubs.map(this.renderRankings)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
            </>
       );
    }
}