import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import firebase from '../../lib/firebase';
import LikeSDK from '../../lib/LikeSDK';
import ShareSDK from '../../lib/ShareSDK';
import {shareBaseUrl} from '../../constants';
import { toast } from 'react-toastify';
import SubscribeSDK from '../../lib/SubscribeSDK';
import Modal from 'react-modal';
import {modalStyle} from '../../lib/Styles';
import ar from '../../locale';
import NewCard from '../../lib/NewCard';

export default class Home extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            academics: [],
            modalIsOpen: false,
            loading: false,
            subscribed: false,
            pageloading: true,
        };
        this.academicRef = firebase.database().ref('academics/');
    }

    componentDidMount() {
        this.academicRef.on('value', response => {
            this.state.academics = Utils.convertToArrayWithInsertionOrder(response.val());
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.academicRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    onLike = (academic) => {
        let {user} = this.state;
        if(user) {
            LikeSDK.like(this.academicRef.child(academic.key))
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        }else{
            this.showLoginDependency();
        }
    }

    onSubscribe = academic => {
        let {user} = this.state;
        if(user) {
            this.state.cur = academic;
            this.toggleModal();
        } else {
            this.showLoginDependency();
        }
    };

    toggleModal = () => {
        this.state.subscribed = false;
        this.state.loading = false;
        this.state.modalIsOpen = !this.state.modalIsOpen;
        this.updateState();
    };

    confirmSubscription = () => {
        this.state.loading = true;
        this.updateState();
        let ref = this.academicRef.child(this.state.cur.key);
        SubscribeSDK.subscribe(ref).then(() => {
            this.state.loading = false;
            this.state.subscribed = true;
            this.updateState();
        });
    };

    loadDetail = (key) => {
        this.props.history.push('/academics/' + key);
    }

    renderAcademicView = (academic) => {
        let path = '/academics/' + academic.key;
        let shareUrl = shareBaseUrl + path;
        let shareMessage = academic.title + '\n';
        return(
            <NewCard
                key={academic.key}
                shareUrl={shareUrl}
                shareMessage={shareMessage}
                path={path}
                timestamp={academic.timestamp}
                title={academic.title}
                likes={academic.likes || 0}
                likedby={academic.likedby || []}
                onLike={this.onLike.bind(this, academic)}
                shares={academic.shares || 0}
                poster={academic.poster}
                showSubscribe={true}
                subscribes={academic.subscribes || 0}
                subscribedby={academic.subscribedby || []}
                onSubscribe={this.onSubscribe.bind(this,academic)}
             />
        );
    }

    render() {
        return (
        <>
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            {/* <h1 className="page-heading__title">Academics & <span className="highlight">Talents</span></h1> */}
                            <h1 className="page-heading__title"><span className="highlight">{ar.academic_talents}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-content">
                <div className="container rtl">
                    {this.state.pageloading && (
                        <div className="text-center">
                            <span className="spinner-border text-success" role="status"></span>
                        </div>
                    )}
                    {!this.state.pageloading && (
                        <div className="posts posts--cards post-grid post-grid--masonry row">
                            {this.state.academics.map(this.renderAcademicView)}
                        </div>
                    )}                
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    overlayClassName="loader"
                    className="my-modal-md"
                    style={modalStyle}>
                    {!this.state.subscribed && (
                        <div className="position-relative">
                            <i className="fa fa-plus-circle fa-3x text-success position-absolute trexa btn-circle"></i>
                            <p className="text-center mt-2">{ar.confirmation_text}</p>
                            <div className="d-flex flex-row justify-content-between mt-2">
                                <button className="btn btn-warning" type="button" onClick={this.toggleModal}>{ar.cancel_subscription}</button>
                                {!this.state.loading && (
                                    <button className="btn btn-primary" type="button" onClick={this.confirmSubscription}>{ar.confirm_subscription}</button>
                                )}
                                {this.state.loading && (
                                    <button className="btn btn-primary" type="button">
                                        <div className="spinner-border spinner-border-sm text-success" role="status"></div>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    {this.state.subscribed && (
                        <div className="position-relative">
                            <i className="fa fa-check-circle fa-3x text-success position-absolute trexa btn-circle"></i>
                            <p className="text-center mt-2">{ar.confirmed_text}</p>
                            <div className="d-flex flex-row justify-content-center mt-2">
                                <button className="btn btn-warning" type="button" onClick={this.toggleModal}>{ar.keep_browsing}</button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        </>
        );
    }

}