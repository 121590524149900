import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Home from './screens/Home';
import NotFound from './screens/NotFound';
import * as serviceWorker from './serviceWorker';
import Login from './screens/Login';
import Header from './screens/Header';
import Footer from './screens/Footer';
import PhoneAuth from './screens/PhoneAuth';
import {AuthContext} from './lib/AuthContext';
import { Route, BrowserRouter as Router, Switch, Redirect, useHistory } from 'react-router-dom';
import firebase, {auth, messaging} from './lib/firebase';
import Chatroom from './screens/Chatroom';
import Chat from './screens/Chat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './screens/ForgotPassword';
import Academics from './screens/Academics';
import AcademicsDetail from './screens/AcademicsDetail';
import Feminine from './screens/Feminine';
import FeminineNews from './screens/Feminine/news';
import FeminineVideos from './screens/Feminine/video';
import FeminineTraining from './screens/Feminine/training';
import Modal from 'react-modal';
import TrainingDetail from './screens/TrainingDetail';
import NewsDetail from './screens/NewsDetail';
import FeminineVideoDetail from './screens/FeminineVideoDetail';
import EgyptianVar from './screens/EgyptianVar';
import EgyptianAwards from './screens/EgyptianAwards';
import EgyptianCoachSingleFormation from './screens/EgyptianCoachSingleFormation';
import Profile from './screens/Profile';
import MyClub from './screens/MyClub';
import MyClubMatches from './screens/MyClub/upcomingMatches';
import MyClubRankings from './screens/MyClub/clubRankings';
import EgyptianCoach from './screens/EgyptianCoach';
import EgyptianCoachLineup from './screens/EgyptianCoach/createFormation';
import EgyptianCoachSuggestedFormation from './screens/EgyptianCoach/suggestedFormations';
import EgyptianCoachExpectations from './screens/EgyptianCoach/expectation';
import MyHobby from './screens/MyHobby';
import MyHobbyIndividualVideo from './screens/MyHobbyIndividualVideo';
import SlideshowIndividual from './screens/SlideshowIndividual';
import ContactUs from './screens/ContactUs';
import MyHobbyConsent from './screens/MyHobbyConsent';
import MyHobbyUpload from './screens/MyHobbyUpload';
import Notifications from './screens/Notifications';
import TermsAndConditions from './screens/TermsConditions';
import Utils from './lib/Utils';
Modal.setAppElement('#root');

const PrivateRoute = ({ user, ...props }) => {
  if(user) {
    let {club,contact,is_contact_verified} = user;
    if(club && contact && is_contact_verified) {
      return <Route { ...props } />;
    } else {
      return <Redirect to="/phoneauth" { ...props }/>;
    }
  } else {
    return <Redirect to="/login" { ...props } />;
  }
}

const SemiPrivateRoute = ({ user, ...props }) => {
  if(user) {
    return <Route { ...props } />;
  } else {
    return <Redirect to="/login" { ...props } />;
  }
}    

function App() {
  
  toast.configure();
  const [authDetails, setAuthDetails] = useState({loaded: false, user: null});
  
  useEffect(() => {
		auth.onAuthStateChanged(user => {
      if(user){
        firebase.database().ref('users/' + user.uid).once('value', response => {
          window.$('#root').css("display", "block");
          window.$('#jpreOverlay').css("display", "none");
          authDetails.loaded = true;
          authDetails.user = response.val();
          setAuthDetails({...authDetails});
        
          //If user is loggedin then subscribe to notifications
          // if ("serviceWorker" in navigator && messaging) {
          //     navigator.serviceWorker.register('/firebase-messaging-sw.js')
          //     .then(function(registration) {
          //       console.log('Hooray. Registration successful, scope is:', registration.scope);
          //       //messaging.useServiceWorker(registration);
                
          //     })
          //     .catch(function(err) {
          //       console.log("Service worker registration failed, error:", err);
          //     });
          // }
          if (messaging) {
            messaging.requestPermission().then(() => messaging.getToken()).then((token) => {
              subscribe(authDetails.user,token);
            }).catch((error) => {});

            //subscribe to foreground message
            messaging.onMessage(onMessage);

            //unsubscribe first on token refresh and then subscribe
            messaging.onTokenRefresh(() => {
              messaging.getToken().then((token) => {
                subscribe(authDetails.user,token);
              }).catch((err) => {});
            });
          }
        });
      } else {
        window.$('#root').css("display", "block");
        window.$('#jpreOverlay').css("display", "none");
        authDetails.loaded = true;
        authDetails.user = null;
        setAuthDetails({...authDetails});
      }
    });
	}, []);

  //When notification is recieved in foreground
  function onMessage(message) {
    toast.info(message.notification.body,{
        position: toast.POSITION.BOTTOM_LEFT,
        onClick: function(event){
          window.location.href = message.data.path;
        },
    });
  };

  function subscribe(user,registrationToken){
    auth.currentUser.getIdToken().then(userToken => {
      if(user.notiposts) {
        Utils.subscribeTopic(userToken,registrationToken, user.uid + '__notiposts', function(){
          //console.log('Subscribe successful for individual post');
        });
      }
      if(user.notiupdates) {
        Utils.subscribeTopic(userToken,registrationToken,'__notiupdates', function(){
          //console.log('Subscribe successful for app updates');
        });
      }
      if(user.noticlub && user.club) {
        Utils.subscribeTopic(userToken,registrationToken, user.club + '__noticlub', function(){
          //console.log('Subscribe successful for club notifications');
        });
      }
    });
  };

  function unsubscribe(user, registrationToken){
    auth.currentUser.getIdToken().then(userToken => {
      Utils.unsubscribeTopic(userToken,registrationToken, user.uid + '__notiposts', function(){
        //console.log('Subscribe successful for individual post');
      });
      Utils.unsubscribeTopic(userToken,registrationToken,'__notiupdates', function(){
        //console.log('Subscribe successful for app updates');
      });
      Utils.unsubscribeTopic(userToken,registrationToken, user.club + '__noticlub', function(){
        //console.log('Subscribe successful for club notifications');
      });
    });
  };

  function getRoutes(){
    return (
      <AuthContext.Provider value={authDetails}>
        <Router>
          <div className="site-wrapper clearfix">
            <Route render={(props) => (<Header {...props} />)} />
            <Switch>
              <Route user={authDetails.user} exact path="/" component={Home} />
              <Route user={authDetails.user} exact path="/chatroom" component={Chatroom} />
              <Route user={authDetails.user} exact path="/academics" component={Academics} />
              <Route user={authDetails.user} path="/academics/:key" component={AcademicsDetail} />
              {/* <Route user={authDetails.user} exact path="/feminine" component={Feminine} /> */}
              <Route user={authDetails.user} exact path="/feminine/news" component={FeminineNews} />
              <Route user={authDetails.user} exact path="/feminine/videos" component={FeminineVideos} />
              <Route user={authDetails.user} exact path="/feminine/training" component={FeminineTraining} />
              <Route user={authDetails.user} path="/feminine/training/:key" component={TrainingDetail} />
              <Route user={authDetails.user} path="/feminine/news/:key" component={NewsDetail} />
              <Route user={authDetails.user} path="/feminine/videos/:key" component={FeminineVideoDetail} />
              <Route user={authDetails.user} exact path="/var" component={EgyptianVar} />
              {/* <Route user={authDetails.user} exact path="/coach" component={EgyptianCoach} /> */}
              <Route user={authDetails.user} exact path="/coach/lineup" component={EgyptianCoachLineup} />
              <Route user={authDetails.user} exact path="/coach/suggested-formations" component={EgyptianCoachSuggestedFormation} />
              <Route user={authDetails.user} exact path="/coach/expectations" component={EgyptianCoachExpectations} />
              <Route user={authDetails.user} path="/clubs_formations/:club/:key" component={EgyptianCoachSingleFormation} />
              <Route user={authDetails.user} exact path="/awards" component={EgyptianAwards} />
              {/* <Route user={authDetails.user} exact path="/my-club" component={MyClub} /> */}
              <Route user={authDetails.user} exact path="/my-club/matches" component={MyClubMatches} />
              <Route user={authDetails.user} exact path="/my-club/rankings" component={MyClubRankings} />
              <Route user={authDetails.user} exact path="/my-hobby" component={MyHobby} />
              <Route user={authDetails.user} exact path="/my-hobby/consent" component={MyHobbyConsent} />
              <Route user={authDetails.user} exact path="/my-hobby/upload" component={MyHobbyUpload} />
              <Route user={authDetails.user} path="/my-hobby/:key" component={MyHobbyIndividualVideo} />
              <Route user={authDetails.user} path="/hobbyvideos/:key" component={MyHobbyIndividualVideo} />
              <Route user={authDetails.user} path="/slideshow/:key" component={SlideshowIndividual} />
              <PrivateRoute user={authDetails.user} exact path="/profile" component={Profile} />
              <PrivateRoute user={authDetails.user} path="/contact-us" component={ContactUs} />
              <PrivateRoute user={authDetails.user} path="/chatroom/:key" component={Chat} />
              <SemiPrivateRoute user={authDetails.user} exact path="/phoneauth" component={PhoneAuth} />
              {/** Notifications needs to in private route */}
              <PrivateRoute user={authDetails.user} exact path="/notifications" component={Notifications} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot" component={ForgotPassword} />
              <Route exact path="/404" component={NotFound} />
              <Route exact path="/terms" component={TermsAndConditions} />
              <Route component={NotFound} />
            </Switch>
            <Footer/>
          </div>
        </Router>
      </AuthContext.Provider>
    );
  }

  function getLoading(){
    return(
      <div/>
    );
  }

  if(!authDetails.loaded){
    return getLoading();
  } else {
    return getRoutes();
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
