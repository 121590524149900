import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import firebase from '../../lib/firebase';
import LikeSDK from '../../lib/LikeSDK';
import {shareBaseUrl} from '../../constants';
import { toast } from 'react-toastify';
import Popover from 'react-popover';
import ar from '../../locale';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TumblrShareButton,
  TumblrIcon,
} from 'react-share';
import moment from 'moment';
import 'moment/locale/ar';
moment.locale('ar');

export default class TrainingDetail extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            training: {
                likedby:[],
            },
            popoverIsOpen: false,
            place: 'above',
            pageloading: true,
        };
        this.trainingRef = firebase.database().ref('training/' + this.props.match.params.key);
        
    }

    componentDidMount() {
        this.trainingRef.on('value', response => {
            this.state.training = response.val();
            this.state.training.likedby = this.state.training.likedby || [];
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.trainingRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    like = (training) => {
        let {user} = this.state;
        if(user) {
            LikeSDK.like(this.trainingRef)
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.danger(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            this.showLoginDependency();
        }
    }

    togglePopover(toState) {
        this.state.popoverIsOpen = typeof toState === "boolean" ? toState : !this.state.popoverIsOpen
        this.updateState();
    }

    onShare = () => {
        this.togglePopover();
    };

    getShareLayout = () => {
        let shareUrl = shareBaseUrl + '/feminine/training/' + this.props.match.params.key;
        let shareMessage = this.state.training.title + '\n';
        return (
            <div className="d-flex flex-row">
                <FacebookShareButton url={shareUrl} quote={shareMessage  + shareUrl}>
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareMessage}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={shareMessage}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <TumblrShareButton url={shareUrl} title={shareMessage}>
                    <TumblrIcon size={32} round={true} />
                </TumblrShareButton>
                <EmailShareButton url={shareUrl} body={shareMessage}>
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </div>
        );
    }

    render() {
        let {user ,training, pageloading} = this.state;
        if(pageloading) {
            return (
                <div className="text-center p-5">
                    <span className="spinner-border text-success" role="status"></span>
                </div>
            );
        }
        let likedColor = user && training.likedby.includes(user.uid) ? 'liked' : '' ;
        return (
            <>
                <div className="page-heading post-back-c p-0 bck-size-cover" style={{'backgroundImage': `url(${training.poster})`}}>
                    <div className="w-100 h-100 tinter">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="post__meta-block post__meta-block--top posts__item--category-2">
                                        <div className="post__category text-center">
                                            <span className="label posts__cat-label">{ar.womens_ball} / {ar.training}</span>
                                        </div>
                                        <h1 className="page-heading__title text-center">{training.title}</h1>
                                        <div className="text-center mt-1 text-white">
                                            <span className="small pl-2 pr-2">
                                                {moment(this.state.training.timestamp).format('ll')}
                                            </span>
                                            <span>-</span>
                                            <span className="small pl-2 pr-2">
                                                { training.likes || 0 } {ar.likes}
                                            </span>
                                        </div>
                                        <div className="text-center mt-2">
                                            <button type="button" className={'btn btn-default btn-single-icon product__wish btn-circle mr-1 ml-1 btn_center ' + likedColor} data-toggle="tooltip" data-placement="top" title={ar.likes} onClick={this.like.bind(this, training)}>
                                                <i className="icon-heart"></i>
                                            </button>
                                            <Popover 
                                                    place={this.state.place} 
                                                    isOpen={this.state.popoverIsOpen}
                                                    onOuterAction={this.togglePopover.bind(this,false)}
                                                    body={this.getShareLayout()}
                                                >
                                                <button type="button" className="btn btn-default btn-single-icon product__wish btn-circle mr-1 ml-1 btn_center" data-toggle="tooltip" data-placement="top" title={ar.share} onClick={this.onShare.bind(this, training)}>
                                                    <i className="icon-share"></i>
                                                </button>
                                            </Popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-content">
			        <div className="container">
                        <div className="row">
                            <div className="content col-lg-8 offset-md-2">
                                <article className="card post post--single post--extra-top">
                                    <div className="card__content no-pmb">
                                        <div className="post__content" dangerouslySetInnerHTML={{ __html: training.content }} >
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}