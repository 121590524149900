import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import firebase from '../../lib/firebase';
import Utils from '../../lib/Utils';
import { toast } from 'react-toastify';
import DraggablePlayer from '../../lib/DraggablePlayer';
import ar from '../../locale';

const allowedPlayers = 11;
export default class CreateFormations extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            players: [],
            playersObj: {},
            form: {
                players: {},
            },
            scrollY: 0,
        };
        
    }

    componentDidMount() {
        //hidng context menu for stickiness of players
        window.addEventListener('contextmenu', function(e) {
            e.preventDefault();
        });
        if (this.state.user) {
            this.clubPlayers = firebase.database().ref('clubs_players/' + this.state.user.club + '/players');
            this.clubFormation = firebase.database().ref('clubs_formations/' + this.state.user.club + '/' + this.state.user.uid);
            let clubP = this.clubPlayers.orderByChild('index').once('value');
            let clubF = this.clubFormation.once('value');
            Promise.all([clubP,clubF]).then((res) => {
                let clubPRes = res[0].val();
                let clubFRes = res[1].val();

                this.state.form = clubFRes;
                //delete this property because it will not be allowed to save in firebase
                if (this.state.form) {
                    delete this.state.form.featured;
                } else {
                    this.state.form = {
                        players: {},
                    };
                }

                this.state.playersObj = clubPRes;
                this.state.players = Utils.convertToArrayWithOrderKeyAsc(clubPRes,'index');
                if(this.state.form && this.state.form.players) {
                    Object.keys(this.state.form.players).forEach(playerKey => {
                        if(this.state.form.players.hasOwnProperty(playerKey) && !this.state.playersObj[playerKey]){
                            delete this.state.form.players[playerKey];
                        }
                    });
                }
                this.updateState();
            });
            
        }
    }

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    getTotalPlayersOnFeild = () => {
        return Object.keys(this.state.form.players).length;
    };

    isAllowedToAddPlayer = player => {
        if (this.getTotalPlayersOnFeild() < allowedPlayers) {
            return true;
        } else {
            //Moved player is already there on field, the allow him
            if (player && this.state.form.players[player.uid]) {
                return true;
            } else {
                return false;
            }
        }
    };

    isUnderDroppable = (curPos) => {
        //Make center as the current position, just for calculation
        let currentPosition = {...curPos};
        currentPosition.x += (46 / 2); //half the width including padding
        currentPosition.y += (46 / 2); // half the height inlcuding padding
        return (
            currentPosition.x > 0 &&
            currentPosition.x <= 280 &&
            currentPosition.y > 0 &&
            currentPosition.y <= 408
        )
    };

    onDrop = (player, originalPosition, revert, dropSuccess, e, node) => {
        let currentPosition = {
            x : originalPosition.x + node.x, //add offset position from node
            y : originalPosition.y + node.y,
        }
        if (this.isUnderDroppable(currentPosition) && this.isAllowedToAddPlayer(player)){
            player.offset = {};
            player.offset.x = node.x;
            player.offset.y = node.y;
            player.position = currentPosition;
            if (!this.state.form.players) {
                this.state.form.players = {};
            }
            this.state.form.players[player.uid] = player;
            dropSuccess();
            this.updateState();
        } else {
            if (!this.isAllowedToAddPlayer(player)) {
                toast.error(ar.max_11_players,{
                    position: toast.POSITION.TOP_CENTER
                });
            }
            if (this.state.form.players) {
                delete this.state.form.players[player.uid];
            }
            revert();
        }
    };

    onSave = () => {
        if (this.getTotalPlayersOnFeild() === allowedPlayers) {
            this.clubFormation
            .update({
                players: {...this.state.form.players},
                name: this.state.user.name,
                avatar: this.state.user.photoURL,
                key: this.state.user.uid,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
            })
            .then(() => {
                toast.success(ar.saved,{
                    position: toast.POSITION.TOP_CENTER
                });
            })
            .catch(error => {
                toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            toast.error(ar.max_11_players,{
                    position: toast.POSITION.TOP_CENTER
            });
        }
    }

    renderPlayers = player => {
        let playerOffset = {x: 0, y: 0};
        let showName = false;
        if (this.state.form) {
            let {players} = this.state.form;
            if (players && players[player.uid]) {
                playerOffset = players[player.uid].offset;
                showName = true;
            }
        }
        return <DraggablePlayer
            key={player.uid}
            player={player}
            offset={playerOffset}
            showName={showName}
            onStop={this.onDrop}
        />;
    };

    renderLoginContent = () => {
        return (
            <div className="site-content">
                <div className="container">
                    <h3>{ar.login_to_access}</h3>
                </div>
            </div>
        );
    }

    render() {
        let {user} = this.state;
        if(user) {
            return (
                <>
                    <div className="page-heading">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <h1 className="page-heading__title"><span className="highlight">{ar.you_r_trainer}</span></h1>
                                    <h4 className="mt-1 text-center">{ar.change_lineup}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="site-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-8 col-lg-9">
                                    <div className="cfield-con m-auto">
                                        <div className="cfield-con position-relative d-flex flex-row-reverse">
                                            <div className="cfield">

                                            </div>
                                            {this.state.players.map(this.renderPlayers)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-lg-3 d-flex flex-column align-items-center pt-3">
                                    <h4 className="text-center">{ar.change_lineup}</h4>
                                    <p className="text-center">{ar.change_linup_how_to}</p>
                                    <button type="button" className="btn btn-primary" onClick={this.onSave}>
                                        {ar.save}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return this.renderLoginContent();
        }
    }
}