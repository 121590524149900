import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import {shareBaseUrl} from '../../constants';
import firebase from '../../lib/firebase';
import LikeSDK from '../../lib/LikeSDK';
import { toast } from 'react-toastify';
import ar from '../../locale';
import NewCard from '../../lib/NewCard';

export default class News extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            news: [],
            pageloading: true,
        };
        this.newsRef = firebase.database().ref('news/');        
    }

    componentDidMount() {
        this.newsRef.on('value', response => {
            this.state.news = Utils.convertToArrayWithInsertionOrder(response.val());
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.newsRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    like = (news) => {
        let {user} = this.state;
        if(user) {
            LikeSDK.like(this.newsRef.child(news.key))
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.danger(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            this.showLoginDependency();
        }
    }
 
    renderNewsView = (news) => {
        let path = '/feminine/news/' + news.key;
        let shareUrl = shareBaseUrl + path;
        let shareMessage = news.title + '\n';
        return (
            <NewCard
                shareUrl={shareUrl}
                shareMessage={shareMessage}
                path={path}
                timestamp={news.timestamp}
                title={news.title}
                likes={news.likes || 0}
                likedby={news.likedby || []}
                shares={news.shares || 0}
                poster={news.poster}
                content={news.content}
                onLike={this.like.bind(this, news)}
                key={news.key}
            />
        )
    }

    render() {
        return (
            <>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <h1 className="page-heading__title"><span className="highlight">{ar.womens_ball}</span></h1>
                                <h4 className="mt-1 text-center">{ar.news}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-content">
                    <div className="container rtl">
                        {this.state.pageloading && (
                            <div className="text-center">
                                <span className="spinner-border text-success" role="status"></span>
                            </div>
                        )}
                        {!this.state.pageloading && (
                            <div className="posts posts--cards post-grid post-grid--masonry row">
                                {this.state.news.map(this.renderNewsView)}    
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}