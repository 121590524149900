import React from 'react'
import Utils from '../../lib/Utils';
import firebase, {auth} from '../../lib/firebase';
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Loading from '../../lib/Loading';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css'
import DatePicker from "react-datepicker";
import ReactSlider from 'react-slider';
import "react-datepicker/dist/react-datepicker.css";
import CustomDateInput from '../../lib/CustomDateInput';
import Cropper from 'react-easy-crop';
import cropImage from './cropImage';
import ar from '../../locale';
import moment from 'moment';
import 'moment/locale/ar';
import { toast } from 'react-toastify';
moment.locale('ar');


const genderOptions = [
    { value: 'male', label: ar.male },
    { value: 'female', label: ar.female },
];

export default class Login extends ParentComponent{
  static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            loading: false,
            clubs: [],
            clubsObj: {},
            otpSent: false,
            otpSending: false,
            showCropper: false,
            imageSrc: null,
            zoom: 1,
            crop: { x: 0, y: 0 },
            cropSize: {width: 300, height: 300},
            token: null,
        };
        this.clubRef = firebase.database().ref('/clubs');
        this.userRef = firebase.database().ref('users/'+this.state.user.uid);
    }

    componentDidMount() {
        auth.currentUser.getIdToken().then(token => {
            this.state.token = token;
            this.updateState();
        });
        this.userRef.on('value', snapshot => {
            this.state.user = snapshot.val();
            this.updateState();
        });
        this.clubRef.on('value', response => {
            let clubsObj = response.val();
            let clubs = Utils.convertToArrayWithOrderKeyAsc(clubsObj, 'order');
            if (clubs) {
                this.state.clubsObj = clubsObj;
                clubs.forEach(club => {
                    let obj = {
                        value : club.key,
                        label: club.name,
                    }
                    this.state.clubs.push(obj);
                })
                this.updateState();
            }
        });
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
        });
    }

    componentWillUnmount() {
        this.clubRef.off('value');
        this.userRef.off('value');
    }

    updateValue = (key, e) => {
        if(key === 'dob'){
            let value = e;
            let dob = moment(value);
            dob.locale('en');
            let modifiedDate = dob.format('YYYY-MM-DD');
            this.state.user[key] = modifiedDate;
        } else if(key === 'contact' || key === 'otp'){
            let value = e.target.value;
            this.state.user[key] = value;
        } else if(key === 'gender' || key === 'club'){
            this.state.user[key] = e.value;
            if (key === 'club') {
                this.state.user.club_name = this.state.clubsObj[e.value].name;
            }
        } else{
            let value = e.target.value;
            this.state.user[key] = value;
        }
        //console.log(this.state.user[key]);
        this.updateState();
    };

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        });
    };

    showError = msg => {
        toast.error(msg, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    showSuccess = msg => {
        toast.success(msg, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    showLoading = show => {
        this.state.loading = show;
        this.updateState();
    };

    save = (e) => {
        e.preventDefault();
        if (this.state.user.club == null) {
          this.showError(ar.enter_valid_club);
        } else if (!Utils.validateName(this.state.user.name)) {
           this.showError(ar.enter_valid_name);
        } else if (!Utils.validateEmail(this.state.user.email)) {
           this.showError(ar.enter_valid_email);
        } else if (this.state.user.password && !Utils.validatePassword(this.state.user.password)) {
           this.showError(ar.enter_valid_password);
        } else {
            //Everything is successful, send him OTP
            this.showLoading(true);
            let {user} = this.state;
            this.updatefirebasePassword(user)
            .then(() => this.updateFirebaseEmail(user))
            .then(() => this.updateFirebaseName(user))
            .then(() => this.updateUserDetails(user))
            .then(() => {
                this.showLoading(false);
                toast.success(ar.profile_updated_successfully, {
                    position: toast.POSITION.TOP_CENTER
                });
            }).catch(error => {
                this.showLoading(false);
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            })
        }
    };

    toggleCropper = () => {
        this.state.showCropper = !this.state.showCropper;
        this.updateState();
    }

    onZoomChange = zoom => {
        this.state.zoom = zoom;
        this.updateState();
    };

    readFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    };

    onCropChange = crop => {
        this.state.crop = crop;
        this.updateState();
    }

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        this.state.croppedAreaPixels = croppedAreaPixels;
        this.updateState();
    }

    onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const imageDataUrl = await this.readFile(e.target.files[0]);
            this.state.imageSrc = imageDataUrl;
            this.toggleCropper();
        }
    };

    cancelPic = () => {
        this.state.imageSrc = null;
        this.state.croppedAreaPixels = null;
        this.file.value = null;
        this.toggleCropper();
    };

    savePic = async () => {
        this.showLoading(true);
        const croppedImage = await cropImage(this.state.imageSrc,this.state.croppedAreaPixels);
        this.uploadPic(croppedImage);
    };

    uploadPic = (media) => {
        if(media) {
            let {user} = this.state;
            firebase
            .storage()
            .ref('users/' + user.uid)
            .put(media)
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then(downloadURL => {
                user.photoURL = downloadURL;
                return auth.currentUser.updateProfile({photoURL: downloadURL});
            })
            .then(() => this.userRef.update({photoURL: user.photoURL}))
            .then(res => {
                this.cancelPic();
                this.showLoading(false);
                toast.success(ar.upload_successful, {
                    position: toast.POSITION.TOP_CENTER
                });
            }).catch(error => {
                this.showLoading(false);
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        }
    };

    updatefirebasePassword = (user) => {
        if (user.password) {
            return auth.currentUser.updatePassword(user.password);
        } else {
            return new Promise((resolve, reject) => resolve(true));
        }
    };

    updateFirebaseEmail = (user) => {
        return auth.currentUser.updateEmail(user.email);
    };

    updateFirebaseName = (user) => {
        return auth.currentUser.updateProfile({
            displayName: user.name,
        });
    };

    updateUserDetails = (user) => {
        delete user.credits;
        delete user.otp;
        return firebase
            .database()
            .ref('users/'+user.uid)
            .update({
                gender: user.gender || null,
                club: user.club,
                club_name: user.club_name,
                dob: user.dob || null,
                name: user.name,
                email: user.email
            });
    };

    openPicker = (e) => {
        this.file.click(e);
    };
 
    render() {
        let {user} = this.state;
        return (
        <div>
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <h1 className="page-heading__title"><span className="highlight">{ar.your_account}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-3">
                            <input type="file" ref={file => this.file = file} className="d-none" onChange={this.onFileChange} accept="image/*" />
                            {this.state.showCropper && this.state.imageSrc && (
                                <>
                                    <Cropper
                                        image={this.state.imageSrc}
                                        crop={this.state.crop}
                                        cropSize={this.state.cropSize}
                                        onCropChange = {this.onCropChange}
                                        onCropComplete = {this.onCropComplete}
                                        zoom={this.state.zoom}
                                        maxZoom={10}
                                        minZoom={1}
                                        classes={{containerClassName: 'prof-con'}}
                                    />
                                    <ReactSlider
                                        className="horizontal-slider w-100 mt-3"
                                        thumbClassName="example-thumb"
                                        trackClassName="example-track"
                                        max={10}
                                        min={1}
                                        step={0.1}
                                        onChange={this.onZoomChange}
                                        renderThumb={(props, state) => <div {...props}></div>}
                                    />
                                    <div className="mt-4">
                                        <button type="button" className="btn btn-primary m-1" onClick={this.savePic}>
                                            {ar.save}
                                        </button>
                                        <button type="button" className="btn btn-warning m-1" onClick={this.cancelPic}>
                                            {ar.cancel}
                                        </button>
                                    </div>
                                </>
                            )}
                            {!this.state.showCropper && (
                                <>
                                    {user.photoURL && (
                                      <img src={user.photoURL} alt="profile" className="profile_img"/>
                                    )}
                                    {!user.photoURL && (
                                      <img src="assets/images/blankuser.png" alt="profile" className="profile_img"/>
                                    )}
                                    <div className="position-absolute d-flex profile_overlay">
                                        <button type="button" onClick={this.openPicker} className="btn btn-default btn-single-icon product__wish btn-circle btn_center" data-toggle="tooltip" data-placement="top" title={ar.edit_profile_pic}>
                                            <i className="fa fa-pencil"></i>
                                        </button>
                                    </div>
                                </>
                            )}
                            
                        </div>          
                    <div className="col-lg-8">
                        <div className="card rtl">
                            <div className="card__header">
                                <h4>Update Profile Details</h4>
                            </div>
                            <div className="card__content">
                            <form onSubmit={this.signup} autoComplete="off">
                                <div className="form-group">
                                    <label htmlFor="register-name">{ar.enter_name} *</label>
                                    <input type="text" name="register-name" className="form-control" onChange={this.updateValue.bind(this,'name')} value={user.name} required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="register-name">{ar.enter_your_email} *</label>
                                    <input type="email" name="register-email" className="form-control" onChange={this.updateValue.bind(this,'email')} value={user.email} required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="register-password">{ar.enter_your_password}</label>
                                    <input type="password" name="register-password" className="form-control" placeholder="Enter your password" onChange={this.updateValue.bind(this,'password')}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="register-name">{ar.enter_valid_gender}</label>
                                    <Dropdown options={genderOptions} onChange={this.updateValue.bind(this,'gender')} value={user.gender} placeholder="Select your gender" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="register-password">{ar.select_club} *</label>
                                    <Dropdown options={this.state.clubs} onChange={this.updateValue.bind(this,'club')} value={user.club} placeholder="Select your club" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="register-name">{ar.birthdate}</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={user.dob ? moment(user.dob,"YYYY-MM-DD").toDate(): null}
                                        onChange={this.updateValue.bind(this,'dob')}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        customInput={<CustomDateInput />}
                                    />
                                </div>
                                <div className="form-group form-group--submit">
                                    <button type="button" onClick={this.save} className="btn btn-default btn-lg btn-block">{ar.save}</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading show={this.state.loading} />
        </div>
        );
    }
};