import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import HobbyList from './hobbyList';
import ar from '../../locale';
import {NavLink} from 'react-router-dom';

export default class MyHobby extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    render() {
        return (
        <>
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <h1 className="page-heading__title"><span className="highlight">{ar.my_hobby}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-content">
                <div className="container">
                    <div className="poster d-flex align-items-center pt-2 pb-2 position-relative">
                        <img src="/assets/images/kick.png" className="position-absolute kick" alt="kick"/>
                        <div className="bg-navy d-flex flex-fill flex-row colconvert align-items-center rounded p-3">
                            <h6 className="mb-0 texter flex-fill text-center">{ar.upload_video_heading}</h6>
                            <NavLink to="/my-hobby/consent" className="btn btn-sm btn-danger rounded">
                                {ar.upload_now}
                            </NavLink>
                        </div>
                    </div>
                    <div className="mt-5">
                        <HobbyList/>
                    </div>
                </div>
            </div>
        </>
        );
    }
}