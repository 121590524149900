import {auth} from '../firebase';

/**
 * Subscribes or UnSubscribes any firebase end point.
 */
const SubscribeSDK = {
  subscribe: firebaseRef => {
    let uid = auth.currentUser.uid;
    return firebaseRef.child('subscribedby').transaction(subscribedby => {
      subscribedby = subscribedby || [];
      if (!subscribedby.includes(uid)) {
        subscribedby.push(uid);
      } else {
        const userIndex = subscribedby.findIndex(i => i === uid);
        subscribedby.splice(userIndex, 1);
      }
      firebaseRef.update({
        subscribes: subscribedby.length,
      });
      return subscribedby;
    });
  },
};

export default SubscribeSDK;