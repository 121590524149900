import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import firebase from '../../lib/firebase';
import LikeSDK from '../../lib/LikeSDK';
import { toast } from 'react-toastify';
import moment from 'moment';
import ar from '../../locale';
import 'moment/locale/ar';
import VideoCard from '../../lib/VideoCard';
moment.locale('ar');

export default class MyHobbyIndividualVideo extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            hobbyvideo: {
                likedby:[],
            },
            popoverIsOpen: false,
            place: 'above',
        };
        this.videosRef = firebase.database().ref('hobbyvideos/' + this.props.match.params.key);
        
    }

    componentDidMount() {
        this.videosRef.on('value', response => {
            this.state.hobbyvideo = response.val();
            this.state.hobbyvideo.likedby = this.state.hobbyvideo.likedby || [];
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.videosRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    onLike = (videos) => {
        let {user} = this.state;
        if(user) {
            LikeSDK.like(this.videosRef)
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.danger(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            this.showLoginDependency();
        }
    }

    render() {
        let {hobbyvideo} = this.state;
        if(!hobbyvideo) {
            return;
        }
        return (
            <div className="site-content">
                <div className="container">
                    <VideoCard
                        key={hobbyvideo.key}
                        videokey={hobbyvideo.key}
                        path={'hobbyvideos/' + hobbyvideo.key}
                        photoUrl={hobbyvideo.photoUrl}
                        name={hobbyvideo.name}
                        likes={hobbyvideo.likes || 0}
                        likedby={hobbyvideo.likedby || []}
                        shares={hobbyvideo.shares || 0}
                        timestamp={hobbyvideo.timestamp}
                        poster={hobbyvideo.poster}
                        video={hobbyvideo.url}
                        flagged={hobbyvideo.flagged}
                        featured={hobbyvideo.featured}
                        age={hobbyvideo.age}
                        country={hobbyvideo.country}
                        comments={hobbyvideo.comments || 0}
                        onLike={this.onLike.bind(this, hobbyvideo, 'hobbyvideos')}
                    />
                </div>
            </div>
        );
    }
}