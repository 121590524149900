import React from 'react'
import firebase from '../../lib/firebase';
import { AuthContext } from '../../lib/AuthContext';
import ParentComponent from '../../lib/ParentComponent';
import { toast } from 'react-toastify';
import ar from '../../locale';

export default class ContactUs extends ParentComponent{
  static contextType = AuthContext;
  constructor(props, context) {
      super(props, context);
      this.state = {
          user: context.user,
      };
      this.contactRef = firebase.database().ref('messages/' + this.state.user.uid);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  updateState = () => {
    this.setState(prev => {
        return {...prev}
    });
  };

    send = () => {
        if (this.subject.value && this.message.value) {
            this.contactRef
            .push()
            .set({
                subject: this.subject.value,
                message: this.message.value,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
            })
            .then(res => {
                this.subject.value='';
                this.message.value='';
                toast.success(ar.message_sent,{
                    position: toast.POSITION.TOP_CENTER
                });
            })
            .catch(error => {
                toast.error(error.message,{
                        position: toast.POSITION.TOP_CENTER
                });
            });
        }
    };

  render() {
    return (
      <div>
		    <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <h1 className="page-heading__title"><span className="highlight">{ar.contact_us}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-content">
                <div className="container rtl">
                    <div className="card">
                        <header className="card__header">
                            <h4>{ar.contact_form}</h4>
                        </header>
                        <div className="card__content">
                            <div className="row">
                                <div className="col-lg-4">
                                    <h5>{ar.contact_form_header}</h5>
                                    <p>{ar.contact_form_content}</p>
                                </div>
                                <div className="col-lg-8">
            
                                    <form action="#" className="contact-form">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="contact-form-subject">{ar.enter_subject}</label>
                                                    <input ref={e=> this.subject = e} type="text" name="contact-form-subject" id="contact-form-subject" className="form-control" placeholder={ar.enter_subject} required/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="contact-form-message">{ar.enter_message}</label>
                                            <textarea ref={e=> this.message = e} name="name" rows="5" className="form-control" placeholder={ar.enter_message} required></textarea>
                                        </div>
                                        <div className="form-group form-group--submit">
                                            <button type="button" className="btn btn-primary-inverse btn-lg btn-block" onClick={this.send}>{ar.send_now}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
            
                        </div>
                    </div>
                </div>
            </div>
      </div>
    );
  }
}