import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import firebase from '../../lib/firebase';
import Modal from 'react-modal';
import {modalStyle} from '../../lib/Styles';
import { toast } from 'react-toastify';
import ar from '../../locale';

export default class Chatroom extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            chatrooms: [],
            modalIsOpen: false,
            pageloading: true,
        };
        this.chatroomRef = firebase.database().ref('chatrooms');
        
    }

    componentDidMount() {
        if(this.state.user) {
            this.userRef = firebase.database().ref('users/'+this.state.user.uid);
            this.userRef.on('value', response => {
                this.state.user = response.val();
                this.updateState();
            });
        }
        this.chatroomRef.on('value', response => {
            this.state.chatrooms = Utils.convertToArrayWithOrderKeyAsc(response.val(), 'order');
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.chatroomRef.off('value');
        if(this.userRef){
            this.userRef.off('value');
        }
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    };

    toggleModal = () => {
        this.state.modalIsOpen = !this.state.modalIsOpen;
        this.updateState();
    }

    loadChat = (key) => {
        this.props.history.push('/chatroom/' + key);
    }

    renderChatView = (chatroom) => {
        let {user} = this.state;
        if(!chatroom.users){
            chatroom.users = [];
        }
        let alreadyJoined = user && chatroom.users.includes(user.uid);
        let innerHTML = (
            <div className="product__img-holder">
                <div className="product__slider">
                    <div className="product__slide">
                        <div className="product__slide-img">
                            {chatroom.avatar && (
                            <img src={chatroom.avatar} alt="avatar" className="avatar" />
                            )}
                            <h3 className="textcenter">
                                <span className="textWhite">{chatroom.name}</span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        );
        return(
            <li key={chatroom.key} className="zoom product__item product__item--color-2 card noBorder">
                {alreadyJoined && (
                    <div className="product__img cursor-pointer" onClick={this.loadChat.bind(this,chatroom.key)}>
                        {innerHTML}
                    </div>
                )}
                {!alreadyJoined && (
                    <div className="product__img">
                        {innerHTML}
                    </div>
                )}
                <div className="product__content card__content">
                    <header className="product__header">
                        <div className="product__header-inner textcenter">
                            {alreadyJoined && (
                                <button type="button" className="textWhite btn " onClick={this.exitChatroom.bind(this,chatroom)}>
                                    <h4 className="textcenter m-0">{ar.leave}</h4>
                                </button>
                            )}
                            {!alreadyJoined && (
                                <button  type="button" className="textWhite btn" onClick={this.joinChatroom.bind(this,chatroom)}>
                                     <h4 className="textcenter m-0">{ar.join}</h4>
                                </button>
                            )}
                        </div>
                    </header>
                </div>
            </li>
        );
    }

    exitChatroom = (chatroom) => {
        let key = chatroom.key;
        this.chatroomRef
        .child(key + '/users')
        .transaction(users => {
            users = users || [];
            if(users.includes(this.state.user.uid)){
                const userIndex = users.findIndex(item => item === this.state.user.uid);
                users.splice(userIndex, 1);
                this.chatroomRef.child(key).update({
                    user_count: users.length,
                });
                const roomIndex = this.state.user.chatrooms.findIndex(item => item === key);
                this.state.user.chatrooms.splice(roomIndex, 1);
                this.userRef.update({
                    chatrooms: this.state.user.chatrooms,
                });
            }
            return users;
        })
        .then(response => {
            toast.success(ar.success_left_room,{
                position: toast.POSITION.TOP_CENTER
            });
            this.updateState();
        })
        .catch(error => {
            alert(error.message);
        });
    }

    joinChatroom = chatroom => {
        let {user} = this.state;
        if(user) {
            this.state.cur = chatroom;
            if (chatroom.banned_users && chatroom.banned_users.includes(user.uid)) {
                toast.error(ar.room_debarred,{
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                this.toggleModal();
            }
        } else {
            this.showLoginDependency();
        }
    };

    join = () => {
        this.toggleModal();
        let chatroom = this.state.cur;
        let key = chatroom.key;
        this.chatroomRef
        .child(key + '/users')
        .transaction(users => {
            users = users || [];
            if(!users.includes(this.state.user.uid)){
                users.push(this.state.user.uid);
                this.state.user.chatrooms = this.state.user.chatrooms || [];
                this.state.user.chatrooms.push(key);
                this.chatroomRef.child(key).update({
                    user_count: users.length,
                });
                this.userRef.update({
                    chatrooms: this.state.user.chatrooms,
                });
            }
            return users;
        })
        .then(response => {
            this.props.history.push('/chatroom/' + key);
        })
        .catch(error => {
            alert(error.message);
        });
    }

    render() {
        return (
            <div>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <h1 className="page-heading__title"><span className="highlight">{ar.chatclubs}</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-content">
                    <div className="container">
                        {this.state.pageloading && (
                            <div className="text-center">
                                <span className="spinner-border text-success" role="status"></span>
                            </div>
                        )}
                        {!this.state.pageloading && (
                            <ul className="products products--grid products--grid-4 products--grid-overlay flex-row-reverse">
                                {this.state.chatrooms.map(this.renderChatView)}
                            </ul>
                        )}
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    overlayClassName="loader"
                    className="my-modal-md"
                    style={modalStyle}>
                    {this.state.cur && (
                        <div className="rtl">
                            <h3 className="text-center text-primary">{ar.adhere_group_policies}</h3>
                            <div className="text-inherit no-pmb" dangerouslySetInnerHTML={{ __html: this.state.cur.policies }} />
                            <div className="d-flex flex-row justify-content-between mt-2">
                                <button className="btn btn-primary" type="button" onClick={this.join}>{ar.agree}</button>
                                <button className="btn btn-warning" type="button" onClick={this.toggleModal}>{ar.cancel}</button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }


}