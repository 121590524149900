import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import firebase from '../../lib/firebase';
import { GiftedChat, Message } from 'react-web-gifted-chat';
import { toast } from 'react-toastify';
import InputToolBar from './inputToolBar';
import Modal from 'react-modal';
import {modalStyle} from '../../lib/Styles';
import MessageVideo from './MessageVideo';
import ar from '../../locale';


const chat_no = 60;
export default class Chat extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: {
                _id: context.user.uid,
                avatar: context.user.photoURL,
                name: context.user.name,
            },
            chatroom: {},
            isLoadingEarlier: false,
            showLoadEarlierButton: false,
            previousTimestamp: null,
            lastTimestamp: 0,
            messages: [],
            modalIsOpen: false,
        };
        this.mediaRef = firebase.storage().ref('chats/' + this.props.match.params.key);
        this.chatRef = firebase.database().ref('chats/' + this.props.match.params.key);
        this.chatroomRef = firebase.database().ref('chatrooms/' + this.props.match.params.key);
    }

    componentDidMount() {
        this.chatroomRef.once('value', snapshot => {
            if (!snapshot.exists()) {
                this.props.history.replace('/404');
            } else {
                this.state.chatroom = snapshot.val();
                this.updateState();
            }
        });
        this.chatRef
            .orderByChild('timestamp')
            .limitToLast(chat_no)
            .once('value', snapshotArray => {
                let messagesArray = Utils.convetPropToArray(snapshotArray.val());
                if (messagesArray.length > 0) {
                this.state.previousTimestamp = messagesArray[0].timestamp;
                this.state.lastTimestamp =
                    messagesArray[messagesArray.length - 1].timestamp;
                if (messagesArray.length >= chat_no) {
                    this.state.showLoadEarlierButton = true;
                }
                    this.pushMessageInState(messagesArray.reverse(), true, false);
                }
                this.listenForNewChats();
            });
    }

    /**
     * Listens for child added for new chats.
     * Only add in UI when it is from another user
     * Also listen for updates from previous timestamp,
     * just to update the status of messages
     */
    listenForNewChats = () => {
        this.chatRef
        .orderByChild('timestamp')
        .startAt(this.state.lastTimestamp + 1)
        .on('child_added', snapshot => {
            let chat = snapshot.val();
            if (chat.user._id !== this.state.user._id) {
                this.pushMessageInState(chat, true, false);
            }
        });
    };

    pushMessageInState = (chat, append = true, isLoadingEarlier = false) => {
        this.setState(previousState => ({
            messages: append
                ? GiftedChat.append(previousState.messages, chat)
                : GiftedChat.prepend(previousState.messages, chat),
            isLoadingEarlier: isLoadingEarlier,
            showLoadEarlierButton: previousState.showLoadEarlierButton,
        }));
    };

    /**
     * Update the sent state of the chat
     * Which eventually leads to tick.
     */
    updateStateOfChat = chat => {
        this.setState(previousState => ({
            messages: previousState.messages.map(m => {
                if (m._id === chat._id) {
                    return {...m, sent: chat.sent, flagged: chat.flagged};
                }
                return m;
            })
        }));
    };

    componentWillUnmount() {
        this.chatRef.off('value');
    }

    toggleModal = () => {
        this.state.modalIsOpen = !this.state.modalIsOpen;
        this.updateState();
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    onPressActionButton = () => {
        this.file.click();
    };

    onFileChange = (e) => {
        const createdAt = new Date();
        let mediaArray = e.target.files;
        Array.from(mediaArray).forEach(media => {
            let chat = {
                user: this.state.user,
                createdAt: createdAt,
                _id: this.messageIdGenerator(),
            };
            let mediaDetails = Utils.getMediaDetails(media);
            chat[mediaDetails.type] = window.URL.createObjectURL(media);
            this.onMediaSelect(chat, media, mediaDetails);
        });
    };

    /**
     * Will be called when media is selected
     */
    onMediaSelect = (chat, media, mediaDetails) => {
        chat.local = true;
        chat.pending = true;
        this.pushMessageInState(chat, true);
        this.mediaRef
            .child(chat._id)
            .put(media)
            .then(snapshot => {
                snapshot.ref.getDownloadURL().then((downloadURL) => {
                    chat[mediaDetails.type] = downloadURL;
                    this.onSend([chat], false);
                });
            })
            .catch(error => {
                alert(error.message);
            });
    };

    onLongPress = (context, chat) => {
       this.state.cur= chat;
       this.toggleModal();
    };

    onDeleteMessage = () => {
      let chat = this.state.cur;
      this.chatRef
        .child(chat._id)
        .update({
          deleted: true,
        })
        .then(() => {
          chat.deleted = true;
          this.toggleModal();
          this.updateStateOfChat(chat);
        })
        .catch(error => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_CENTER
          });
        });
    };

    /**
     * Flags a message for being an inappropriate message
     */
    onFlagMessage = () => {
        let chat = this.state.cur;
        let flaggedBy = chat.flaggedBy || [];
        flaggedBy.push(this.state.user);
        this.toggleModal();
        this.chatRef
        .child(chat._id)
        .update({
            flagged: true,
            flaggedBy: flaggedBy,
        })
        .then(() => {
            chat.flagged = true;
            this.updateStateOfChat(chat);
            toast.success(ar.feedback_recieved, {
                position: toast.POSITION.TOP_CENTER
            });
        })
        .catch(error => {
            alert(error.message);
        });
    };

    /**
     * Generates a new message id for the firebase chat
     */
    messageIdGenerator = () => {
        return this.chatRef.push().key;
    };


    onLoadEarlier = () => {
        this.pushMessageInState([], true, true);
        /**Fetch the previous messages */
        this.chatRef
        .orderByChild('timestamp')
        .endAt(this.state.previousTimestamp - 1)
        .limitToLast(chat_no)
        .once('value', snapshotArray => {
            let messagesArray = Utils.convetPropToArray(snapshotArray.val());
            this.state.previousTimestamp = messagesArray[0].timestamp;
            if (messagesArray.length < chat_no) {
            this.state.showLoadEarlierButton = false;
            }
            this.pushMessageInState(messagesArray.reverse(), false, false);
        })
        .catch(error => {
            this.state.showLoadEarlierButton = false;
            this.pushMessageInState([], false, false);
        });
    };

    onSend = (messages = [], push = true) => {
        let chat = messages[0];
        chat.createdAt = chat.createdAt.toISOString();
        if (push) {
            chat.pending = true;
            this.pushMessageInState(chat, true);
        }
        this.chatRef
        .child(chat._id)
        .set({
            ...chat,
            pending: null,
            sent: null,
            local: null,
            filename: null,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
        })
        .then(response => {
            //update the state of chat as checked because its delivered
            chat.sent = true;
            chat.pending = false;
            this.updateStateOfChat(chat);
        })
        .catch(error => {
            alert(error.message);
        });
    };

    renderMessage = props => {
      let {currentMessage} = props;
      if (currentMessage.deleted) {
        return null;
      }
      return <Message {...props} />;
    }

    render() {
        let {chatroom, user, cur, modalIsOpen} = this.state;
        return (
            <>
                <div className="row">
                    <input type="file" ref={(file) => this.file = file} multiple className="d-none" accept="image/*,video/*" onChange={this.onFileChange}/>
                    <div className="col-sm-8 col-lg-9  height-chatbox">
                        <GiftedChat
                            messages={this.state.messages}
                            extraData={this.state}
                            user={this.state.user}
                            alwaysShowSend={true}
                            showUserAvatar={true}
                            renderUsernameOnMessage={true}
                            renderLoadEarlier={this.renderLoadEarlier}
                            onSend={this.onSend}
                            loadEarlier={this.state.showLoadEarlierButton}
                            onLoadEarlier={this.onLoadEarlier}
                            isLoadingEarlier={this.state.isLoadingEarlier}
                            messageIdGenerator={this.messageIdGenerator}
                            onLongPress={this.onLongPress}
                            onPressActionButton={this.onPressActionButton}
                            renderMessage={this.renderMessage}
                            renderMessageVideo={(props) => <MessageVideo {...props} />}
                            renderInputToolbar={(props) => <InputToolBar {...props}/>}
                        />
                    </div>
                    <div className="col-sm-4 col-lg-3 d-flex flex-column align-items-center pt-5 extra-bg order-first order-sm-last">
                        <img src={chatroom.avatar} alt="avatar" className="avatar" />
                        <h3 className="text-center">
                            <span className="textWhite">{chatroom.name}</span>
                        </h3>
                        <h6>
                            {chatroom.users ? chatroom.users.length : 0} {ar.subscribers}
                        </h6>
                    </div>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    overlayClassName="loader"
                    className="modal-md"
                    style={modalStyle}>
                    {cur && cur.user._id !== user._id && (
                        <>
                            <p className="text-center text-primary">{ar.flag_msg_conrimation}</p>
                            <div className="d-flex flex-row justify-content-between mt-2">
                                <button className="btn btn-warning" type="button" onClick={this.toggleModal}>{ar.cancel}</button>
                                <button className="btn btn-primary" type="button" onClick={this.onFlagMessage}>{ar.ok}</button>
                            </div>
                        </>
                    )}
                    {cur && cur.user._id === user._id && (
                        <>
                            <p className="text-center text-primary">{ar.delete_msg_conrimation}</p>
                            <div className="d-flex flex-row justify-content-between mt-2">
                                <button className="btn btn-warning" type="button" onClick={this.toggleModal}>{ar.cancel}</button>
                                <button className="btn btn-primary" type="button" onClick={this.onDeleteMessage}>{ar.ok}</button>
                            </div>
                        </>
                    )}
                </Modal>
            </>
        );
    }
}