import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import Utils from '../../lib/Utils';
import { AuthContext } from '../../lib/AuthContext';
import Matches from '../../lib/Matches';
import firebase from '../../lib/firebase';
import moment from 'moment';
import ar from '../../locale';
import 'moment/locale/ar';
import EmptyCard from '../../lib/EmptyCard';
moment.locale('ar');

export default class UpcominMatches extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            matches: {},
            unfilterdMatches: {},
            popoverIsOpen: false,
            place: 'above',
            pageloading: true,
        };
        this.matchRefTeam1 = firebase.database().ref('matches/');
        this.matchRefTeam2 = firebase.database().ref('matches/');
    }

    componentDidMount() {
       let {user} = this.state;
       if (user && user.club) {
            this.matchRefTeam1
            .orderByChild('team1')
            .equalTo(user.club)
            .on('value', response => {
                let matches = response.val();
                Object.assign(this.state.unfilterdMatches, matches);
                this.filterMatches();
            });

            this.matchRefTeam2
            .orderByChild('team2')
            .equalTo(user.club)
            .on('value', response => {
                let matches = response.val();
                Object.assign(this.state.unfilterdMatches, matches);
                this.filterMatches();
            });
       } else {
           this.matchRefTeam1
            .orderByChild('team1')
            .on('value', response => {
                let matches = response.val();
                Object.assign(this.state.unfilterdMatches, matches);
                this.filterMatches();
            });

            this.matchRefTeam2
            .orderByChild('team2')
            .on('value', response => {
                let matches = response.val();
                Object.assign(this.state.unfilterdMatches, matches);
                this.filterMatches();
            });
       }
    }

    componentWillUnmount() {
        this.matchRefTeam1.off('value');
        this.matchRefTeam2.off('value');
    }

    filterMatches = () => {
        for (var key in this.state.unfilterdMatches) {
            let match = this.state.unfilterdMatches[key];
            if (
                this.state.unfilterdMatches.hasOwnProperty(key) &&
                moment().isSameOrBefore(moment(match.on), 'day')
            ) {
                if (this.props.startedonly && this.props.startedonly === true) {
                    if (match.started) {
                        this.state.matches[key] = match;
                    }
                } else {
                    this.state.matches[key] = match;
                }
            }
        }
        this.state.pageloading = false;
        this.updateState();
    };

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    renderMatches = (match, index) => {
        return (
            <Matches
                key={match.key}
                on={match.on}
                started={match.started}
                started_timestamp = {match.started_timestamp}
                team1_score = {match.team1_score || 0}
                team2_score = {match.team2_score || 0}
                team1_avatar = {match.team1_avatar}
                team2_avatar = {match.team2_avatar}
                team1_name = {match.team1_name}
                team2_name = {match.team2_name}
                team1_venue = {match.team1_venue}
                team2_venue = {match.team2_venue}
                venue={match.venue}
            />
        );
    }

    render() {
        let matches = Utils.convertToArrayWithOrderKeyAsc(this.state.matches, 'on');
        let {hidebanner} = this.props;
        return (
            <>
                {!hidebanner && (
                    <div className="page-heading">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    {this.state.user && (
                                        <h1 className="page-heading__title"><span className="highlight">{ar.my_club}</span></h1>
                                    )}
                                    {!this.state.user && (
                                        <h1 className="page-heading__title"><span className="highlight">{ar.clubs}</span></h1>
                                    )}
                                    <h4 className="mt-1 text-center">{ar.matches}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="site-content">
                    <div className="container">
                        <div className="">
                            {this.state.pageloading && (
                                <div className="text-center">
                                    <span className="spinner-border text-success" role="status"></span>
                                </div>
                            )}
                            {!this.state.pageloading && (
                                <div className="card">
                                    <div className="rtl">
                                        <header className="card__header card__header--has-btn">
                                            <h4>{ar.upcoming_current_matches}</h4>
                                        </header>
                                    </div>
                                    <div className="card__content">
                                        {matches && matches.length === 0 && (
                                            <span>{ar.no_matches}</span>
                                        )}
                                        {matches.map(this.renderMatches)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    render2() {
        let matches = Utils.convertToArrayWithOrderKeyAsc(this.state.matches, 'on');
        return (
            <div className="rtl">
                <div className="products products--grid products--grid-3 products--grid-overlay">
                    {matches && matches.length === 0 && (
                        <span>{ar.no_matches}</span>
                    )}
                    {matches.map(this.renderMatches)}
                </div>
            </div>
        );
    }
}