import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import {shareBaseUrl} from '../../constants';
import firebase from '../../lib/firebase';
import LikeSDK from '../../lib/LikeSDK';
import { toast } from 'react-toastify';
import ar from '../../locale';
import NewCard from '../../lib/NewCard';

export default class Training extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            training: [],
            pageloading: true,
        };
        this.trainingRef = firebase.database().ref('training/');
        
    }

    componentDidMount() {
        this.trainingRef.on('value', response => {
            this.state.training = Utils.convertToArrayWithInsertionOrder(response.val());
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.trainingRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    like = (training) => {
        let {user} = this.state;
        if(user) {
            LikeSDK.like(this.trainingRef.child(training.key))
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.danger(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            this.showLoginDependency();
        }
    }

    renderTrainingView = (training) => {
        let path = '/feminine/training/' + training.key;
        let shareUrl = shareBaseUrl + path;
        let shareMessage = training.title + '\n';
        return (
            <NewCard
                shareUrl={shareUrl}
                shareMessage={shareMessage}
                path={path}
                timestamp={training.timestamp}
                title={training.title}
                likes={training.likes || 0}
                likedby={training.likedby || []}
                shares={training.shares || 0}
                poster={training.poster}
                content={training.content}
                onLike={this.like.bind(this, training)}
                key={training.key}
            />
        )
    }

    render() {
        return (
            <>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <h1 className="page-heading__title"><span className="highlight">{ar.womens_ball}</span></h1>
                                <h4 className="mt-1 text-center">{ar.training}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-content">
                    <div className="container rtl">
                        {this.state.pageloading && (
                            <div className="text-center">
                                <span className="spinner-border text-success" role="status"></span>
                            </div>
                        )}
                        {!this.state.pageloading && (
                            <div className="posts posts--cards post-grid post-grid--masonry row">
                                {this.state.training.map(this.renderTrainingView)}
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}