import React from 'react'
import ParentComponent from '../ParentComponent';
import { AuthContext } from '../AuthContext';
import Popover from 'react-popover';
import { Player } from 'video-react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TumblrShareButton,
  TumblrIcon,
} from 'react-share';
import moment from 'moment';
import 'moment/locale/ar';
moment.locale('ar');

export default class FeminineVideo extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            popoverIsOpen: false,
            place: 'above',
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };
    togglePopover(toState) {
        this.state.popoverIsOpen = typeof toState === "boolean" ? toState : !this.state.popoverIsOpen
        this.updateState();
    }

    onShare = () => {
        this.togglePopover();
        if (this.props.onShare) {
            this.props.onShare();
        }
    };

    getShareLayout = () => {
        let {shareUrl, shareMessage} = this.props;
        return (
            <div className="d-flex flex-row">
                <FacebookShareButton url={shareUrl} quote={shareMessage + shareUrl}>
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareMessage}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={shareMessage}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <TumblrShareButton url={shareUrl} title={shareMessage}>
                    <TumblrIcon size={32} round={true} />
                </TumblrShareButton>
                <EmailShareButton url={shareUrl} body={shareMessage}>
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </div>
        );
    }

    render() {
        let {user} = this.state;
        let {likes, likedby, shares, timestamp, poster, title, video} = this.props;
        let likedColor = (user && likedby.includes(user.uid)) ? 'text-danger' : '' ;
        return(
            <li className="product__item product__item--color-2 card noBorder backgroundCard border-radius">
                <div className="product__img position-relative cursor-pointer">
                    <Player
                        fluid={false}
                        height={250}
                        width="100%"
                        src={video}
                        poster={poster}>
                    </Player>
                </div>
                <div className="product__content card__content px-2">
                    <header className="product__header d-flex flex-row">
                        <div className="mr-auto">
                            <i className={'fa fa-heart mr-2 cursor-pointer ' + likedColor} onClick={this.props.onLike}></i>
                            <p className="display-inline mr-3 m-0">{likes || 0}</p>
                            <Popover 
                                place={this.state.place} 
                                isOpen={this.state.popoverIsOpen}
                                onOuterAction={this.togglePopover.bind(this,false)}
                                body={this.getShareLayout()}
                            >
                                <i className="fa fa-share-alt mr-2 cursor-pointer" 
                                onClick={this.onShare}></i>
                            </Popover>
                            <p className="display-inline m-0">{shares || 0}</p>
                        </div>
                        <div className="text-white">{title}</div>
                    </header>
                    <div>
                        <p className="mb-0 small">{moment(timestamp).format('ll')}</p>
                    </div>
                </div>
            </li>
        );
    }
}