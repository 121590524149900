import React from 'react'
import Utils from '../../lib/Utils';
import firebase, {auth} from '../../lib/firebase';
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Loading from '../../lib/Loading';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css'
import DatePicker from "react-datepicker";
import ReactSlider from 'react-slider';
import "react-datepicker/dist/react-datepicker.css";
import CustomDateInput from '../../lib/CustomDateInput';
import Cropper from 'react-easy-crop';
import ar from '../../locale';
import moment from 'moment';
import 'moment/locale/ar';
import { toast } from 'react-toastify';
moment.locale('ar');

export default class Notifications extends ParentComponent{
  static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            loading: false,
            notifications: [],
        };
    }

    componentDidMount() {
        let {user} = this.state;
        if (user) {
            this.notiPostsRef = firebase.database().ref('notifications/notiposts/' + user.uid);
            this.notiUpdateRef = firebase.database().ref('notifications/notiupdates/');
            this.notiClubRef = firebase.database().ref('notifications/noticlub');
            let pNotiPosts = this.notiPostsRef.once('value');
            let pNotiUpdate = this.notiUpdateRef.once('value');
            let pNotiClub = this.notiClubRef.child(user.club).once('value');
            Promise.all([pNotiPosts, pNotiUpdate, pNotiClub]).then(values => {
                let pNotiPostsVal = values[0].val();
                let pNotiUpdateVal = values[1].val();
                let pNotiClubVal = values[2].val();
                let notiArr = this.processNotiPosts(pNotiPostsVal);
                notiArr = notiArr.concat(this.processNotiUpdates(pNotiUpdateVal));
                notiArr = notiArr.concat(this.processNotiClub(pNotiClubVal));
                //Sort based on the time line
                notiArr = Utils.convertToArrayWithOrderKeyDesc(notiArr, 'timestamp');
                this.state.notifications = notiArr;
                this.updateState();
            });
        }
    }

    processNotiClub = noticlub => {
        let final = [];
        if (noticlub) {
        Object.keys(noticlub).forEach(key => {
            let innerObj = noticlub[key];
            final.push(innerObj);
        });
        }
        return final;
    };

    processNotiUpdates = notiupdates => {
        let final = [];
        if (notiupdates) {
        Object.keys(notiupdates).forEach(key => {
            let innerObj = notiupdates[key];
            final.push(innerObj);
        });
        }
        return final;
    };

    processNotiPosts = notiposts => {
        let final = [];
        if (notiposts) {
        Object.keys(notiposts).forEach(key => {
            let innerObj = notiposts[key];
            Object.keys(innerObj).forEach(innerKey => {
            let finalObj = innerObj[innerKey];
            final.push(finalObj);
            });
        });
        }
        return final;
    };

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    showError = msg => {
        toast.error(msg, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    showSuccess = msg => {
        toast.success(msg, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    showLoading = show => {
        this.state.loading = show;
        this.updateState();
    };

    renderNotifications = (noti,index) => {
        return (
            <div className="mb-2 p-2 rounded" key={index+''} style={{backgroundColor: '#282840'}}>
                <p className="mb-0">{noti.body}</p>
                <span className="small">{moment(noti.timestamp).fromNow()}</span>
            </div>
        );
    };


    render() {
        let {user} = this.state;
        return (
        <div>
            {/* <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <h1 className="page-heading__title"><span className="highlight">{ar.notifications}</span></h1>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="site-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            {this.state.notifications.map((item,index) => this.renderNotifications(item,index))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
};