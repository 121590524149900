import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import firebase from '../../lib/firebase';
import LikeSDK from '../../lib/LikeSDK';
import {shareBaseUrl} from '../../constants';
import { toast } from 'react-toastify';
import Popover from 'react-popover';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TumblrShareButton,
  TumblrIcon,
} from 'react-share';
import SubscribeSDK from '../../lib/SubscribeSDK';
import Modal from 'react-modal';
import {modalStyle} from '../../lib/Styles';
import ar from '../../locale';
import moment from 'moment';
import 'moment/locale/ar';
moment.locale('ar');

export default class AcademicsDetail extends ParentComponent {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            academic: null,
            popoverIsOpen: false,
            place: 'above',
            modalIsOpen: false,
            loading: false,
            subscribed: false,
        };
        this.academicRef = firebase.database().ref('academics/' + this.props.match.params.key);
    }

    componentDidMount() {
        this.academicRef.on('value', response => {
            this.state.academic = response.val();
            this.state.academic.likedby = this.state.academic.likedby || [];
            this.state.academic.subscribedby = this.state.academic.subscribedby || [];
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.academicRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        });
    }

    togglePopover(toState) {
        this.state.popoverIsOpen = typeof toState === "boolean" ? toState : !this.state.popoverIsOpen
        this.updateState();
    }

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    onLike = (academic) => {
        let {user} = this.state;
        if(user) {
            LikeSDK.like(this.academicRef)
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.danger(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            this.showLoginDependency();
        }
    }

    onShare = () => {
        this.togglePopover();
    };

    getShareLayout = () => {
        let shareUrl = shareBaseUrl + '/academics/' + this.props.match.params.key;
        let shareMessage = this.state.academic.title + '\n';
        return (
            <div className="d-flex flex-row">
                <FacebookShareButton url={shareUrl} quote={shareMessage + shareUrl}>
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareMessage}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={shareMessage}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <TumblrShareButton url={shareUrl} title={shareMessage}>
                    <TumblrIcon size={32} round={true} />
                </TumblrShareButton>
                <EmailShareButton url={shareUrl} body={shareMessage}>
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </div>
        );
    };

    onSubscribe = () => {
        let {user} = this.state;
        if(user) {
            this.toggleModal();
        } else {
            this.showLoginDependency();
        }
    };

    toggleModal = () => {
        this.state.subscribed = false;
        this.state.loading = false;
        this.state.modalIsOpen = !this.state.modalIsOpen;
        this.updateState();
    };

    confirmSubscription = () => {
        this.state.loading = true;
        this.updateState();
        SubscribeSDK.subscribe(this.academicRef).then(() => {
            this.state.loading = false;
            this.state.subscribed = true;
            this.updateState();
        });
    };

    render() {
        if(!this.state.academic) {
            return (
                <div className="text-center p-5">
                    <span className="spinner-border text-success" role="status"></span>
                </div>
            );
        }
        let {user ,academic} = this.state;
        let likedColor = (user && academic.likedby.includes(user.uid)) ? 'liked' : '' ;
        let alreadySubscribed = user && academic.subscribedby.includes(user.uid);
        return (
            <div>
			    <div className="page-heading post-back-c p-0 bck-size-cover" style={{'backgroundImage': `url(${academic.poster})`}}>
                    <div className="w-100 h-100 tinter">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="post__meta-block post__meta-block--top posts__item--category-2">
                                        <div className="post__category text-center">
                                            <span className="label posts__cat-label">{ar.academic_talents}</span>
                                        </div>
                                        <h1 className="page-heading__title text-center">{academic.title}</h1>
                                        <div className="text-center mt-1 text-white">
                                            <span className="small pl-2 pr-2">
                                                {moment(academic.timestamp).format('ll')}
                                            </span>
                                            <span>-</span>
                                            <span className="small pl-2 pr-2">
                                                { academic.likes || 0 } {ar.likes}
                                            </span>
                                        </div>
                                        <div className="text-center mt-2">
                                            <button type="button" className={'btn btn-default btn-single-icon product__wish btn-circle mr-1 ml-1 btn_center ' + likedColor} data-toggle="tooltip" data-placement="top" title={ar.likes} onClick={this.onLike.bind(this, academic)}>
                                                <i className="icon-heart"></i>
                                            </button>
                                            <Popover 
                                                    place={this.state.place} 
                                                    isOpen={this.state.popoverIsOpen}
                                                    onOuterAction={this.togglePopover.bind(this,false)}
                                                    body={this.getShareLayout()}
                                                >
                                                <button type="button" className="btn btn-default btn-single-icon product__wish btn-circle mr-1 ml-1 btn_center" data-toggle="tooltip" data-placement="top" title={ar.share} onClick={this.onShare.bind(this, academic)}>
                                                    <i className="icon-share"></i>
                                                </button>
                                            </Popover>
                                            {!alreadySubscribed && (
                                                <button onClick={this.onSubscribe}  type="button" className="btn btn-default btn-single-icon mr-1 ml-1 product__wish btn-circle btn_center" data-toggle="tooltip" data-placement="top" title={ar.subscribe_now}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-content">
			        <div className="container">
                        <div className="row">
                            <div className="content col-lg-8 offset-md-2">
                                <article className="card post post--single post--extra-top">
                                    <div className="card__content no-pmb">
                                        <div className="post__content" dangerouslySetInnerHTML={{ __html: academic.content }} ></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    overlayClassName="loader"
                    className="my-modal-md"
                    style={modalStyle}>
                    {!this.state.subscribed && (
                        <div className="position-relative">
                            <i className="fa fa-plus-circle fa-3x text-success position-absolute trexa btn-circle"></i>
                            <p className="text-center mt-2">{ar.confirmation_text}</p>
                            <div className="d-flex flex-row justify-content-between mt-2">
                                <button className="btn btn-warning" type="button" onClick={this.toggleModal}>{ar.cancel_subscription}</button>
                                {!this.state.loading && (
                                    <button className="btn btn-primary" type="button" onClick={this.confirmSubscription}>{ar.confirm_subscription}</button>
                                )}
                                {this.state.loading && (
                                    <button className="btn btn-primary" type="button">
                                        <div className="spinner-border spinner-border-sm text-success" role="status"></div>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    {this.state.subscribed && (
                        <div className="position-relative">
                            <i className="fa fa-check-circle fa-3x text-success position-absolute trexa btn-circle"></i>
                            <p className="text-center mt-2">{ar.confirmed_text}</p>
                            <div className="d-flex flex-row justify-content-center mt-2">
                                <button className="btn btn-warning" type="button" onClick={this.toggleModal}>{ar.keep_browsing}</button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>            
        );
    }
}