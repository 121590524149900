import React from 'react'
import firebase from '../../lib/firebase';
import ar from '../../locale';
import ArabicNumbers from '../../lib/ArabicNumbers';
import {
  NavLink, 
  Link,
} from "react-router-dom";

export default class Footer extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = {
        social : {},
    };
    this.socialRef = firebase.database().ref('/social');
  }

    componentDidMount() {
        this.socialRef.on('value', response => {
            this.state.social = response.val();
            this.updateState();
        });
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    componentWillUnmount() {
        this.socialRef.off('value');
    }

  render() {
    let {social} = this.state;
    return (
        <footer id="footer" className="footer rtl">
            <div className="footer-info">
                <div className="container">
        
                    <div className="footer-info__inner">
                        <div className="footer-logo footer-logo--has-txt">
                            <NavLink to="/">
                                <img src="assets/images/football/logo-footer.png" srcSet="assets/images/football/logo-footer@2x.png 2x" alt="The Alchemists" className="footer-logo__img" />
                                <div className="footer-logo__heading">
                                    <h5 className="footer-logo__txt">Egyptian Coach</h5>
                                    <span className="footer-logo__tagline">{ar.sports_and_talent}</span>
                                </div>
                            </NavLink>
                        </div>
                        <div className="info-block info-block--horizontal">
                            <div className="info-block__item text-right text-md-center untrudell">
                                <a href="https://apps.apple.com/in/app/egptian-coach/id1491911684" className="m-1">
                                    <img src="assets/images/itunes.png" alt="Itunes Store" width="150" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.egyptiancoach&hl=en" className="m-1">
                                    <img src="assets/images/playstore.png" alt="Play Store" width="150" />
                                </a>
                            </div>
                            <div className="info-block__item px-2">
                                <ul className="social-links social-links--circle">

                                    <li className="social-links__item">
                                        <a href={social.facebook} className="social-links__link"><i className="fa fa-facebook"></i></a>
                                    </li>
                                    <li className="social-links__item">
                                        <a href={social.twitter} className="social-links__link"><i className="fa fa-twitter"></i></a>
                                    </li>
                                    <li className="social-links__item">
                                        <a href={social.instagram} className="social-links__link"><i className="fa fa-instagram"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header__top-bar">
                <div className="container header__top-bar-inner justify-content-between p-3 text-sm-center">
                   <span className="text-xs-right">{ar.copyright} &copy; | جميع الحقوق محفوظة لشركة كود 2019 - 2020</span>
                   <NavLink className="text-default" to='/terms'>{ar.terms_conditions}</NavLink>
                </div>
            </div>
        </footer>
    );
  }
}