import React from 'react'
import ParentComponent from '../ParentComponent';
import { AuthContext } from '../AuthContext';
import Popover from 'react-popover';
import {
  NavLink
} from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TumblrShareButton,
  TumblrIcon,
} from 'react-share';
import ar from '../../locale';
import moment from 'moment';
import 'moment/locale/ar';
moment.locale('ar');

export default class NewCard extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            popoverIsOpen: false,
            place: 'above',
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };
    togglePopover(toState) {
        this.state.popoverIsOpen = typeof toState === "boolean" ? toState : !this.state.popoverIsOpen
        this.updateState();
    }

    onShare = () => {
        this.togglePopover();
        if (this.props.onShare) {
            this.props.onShare();
        }
    };

    getShareLayout = () => {
        let {shareUrl, shareMessage} = this.props;
        return (
            <div className="d-flex flex-row">
                <FacebookShareButton url={shareUrl} quote={shareMessage + '\n' + shareUrl}>
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareMessage}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={shareMessage}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <TumblrShareButton url={shareUrl} title={shareMessage}>
                    <TumblrIcon size={32} round={true} />
                </TumblrShareButton>
                <EmailShareButton url={shareUrl} body={shareMessage}>
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </div>
        );
    }

    render() {
        let {user} = this.state;
        let {path, likedby, timestamp, likes, shares,title, poster, onLike, showSubscribe, onSubscribe,subscribedby } = this.props;
        let likedColor = (user && likedby.includes(user.uid)) ? 'liked' : '' ;
        let alreadySubscribed = user && subscribedby && subscribedby.includes(user.uid);
        return(
            <div className="post-grid__item col-md-6 col-lg-4">
                <div className="posts__item posts__item--card posts__item--category-1 card">
                    <figure className="posts__thumb">
                        <NavLink to={path}><img src={poster} alt="Card" /></NavLink>
                    </figure>
                    <div className="posts__inner card__content">
                        {showSubscribe && !alreadySubscribed && (
                            <button className="posts__cta border-0" onClick={onSubscribe}></button>
                        )}
                        <p className="text-left small">
                            {likes} {ar.likes}&nbsp;&nbsp;-&nbsp;&nbsp;
                            {timestamp && (
                                moment(timestamp).format('ll')
                            )}
                        </p>
                        <h6 className="posts__title more-lh">
                            <NavLink to={path}>{title}</NavLink>
                        </h6>
                    </div>
                    <footer className="posts__footer card__footer">
                        <div className="post-author text-right">
                            <button type="button" className={'m-2 btn btn-default btn-single-icon product__wish btn-circle btn_center ' + likedColor} data-toggle="tooltip" data-placement="top" title={ar.likes} onClick={onLike}>
                                <i className="icon-heart"></i>
                            </button>
                            <Popover 
                                place={this.state.place} 
                                isOpen={this.state.popoverIsOpen}
                                onOuterAction={this.togglePopover.bind(this,false)}
                                body={this.getShareLayout()}
                            >
                                <button type="button" className="m-2 btn btn-default btn-single-icon product__wish btn-circle btn_center" data-toggle="tooltip" data-placement="top" title={ar.share} onClick={this.onShare}>
                                    <i className="icon-share"></i>
                                </button>
                            </Popover>
                        </div>
                        <div className="post__meta meta"></div>
                    </footer>
                </div>
            </div>
        );
    }
}