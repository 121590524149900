import React from 'react'

export default class ParentComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  componentWillUnmount() {}
}