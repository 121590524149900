import React, {forwardRef} from 'react'

const DateInput = ({ value, onClick }, ref) => {
    return <input
            type="text"
            name="register-dob"
            defaultValue={value}
            onClick={onClick}
            className="form-control"
            placeholder="YYYY-MM-DD"
            required 
        />
};

export default forwardRef(DateInput);