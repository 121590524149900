import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import firebase from '../../lib/firebase';
import Utils from '../../lib/Utils';
import { toast } from 'react-toastify';
import VideoCard from '../../lib/VideoCard';
import LikeSDK from '../../lib/LikeSDK';
import ar from '../../locale';

export default class HobbyList extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            cur: {},
            hobbyvideos: [],
            pageloading: true,
        };
        this.videosRef = firebase.database().ref('hobbyvideos/');
    }

    componentDidMount() {
        if (this.props.featuredonly === true) {
            this.videosRef
            .orderByChild('featured')
            .equalTo(true)
            .on('value', this.videoDataHandler);
        } else {
            this.videosRef
            .orderByChild('featured')
            .on('value', this.videoDataHandler);
        }
    }

    componentWillUnmount() {
        this.videosRef.off('value');
    }

    videoDataHandler = response => {
        let featuredData = [];
        let otherData = []
        let hobbyvideos = Utils.convertToArrayWithOrderKeyDesc(
            response.val(),'on'
        );
        hobbyvideos.forEach(item => {
            if(item.reviewed && item.featured){
                featuredData.push(item);
            } else {
                if(item.reviewed) {
                    otherData.push(item);
                }
            }
        });
        this.state.hobbyvideos = [...featuredData, ...otherData];
        this.state.pageloading = false;
        this.updateState();
    };

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    onLike = (item, type) => {
        let {user} = this.state;
        if(user) {
            let ref = this.videosRef.child(item.key);
            LikeSDK.like(ref)
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            this.showLoginDependency();
        }
    };

    renderHobbyVideo = hobbyvideo => {
        return(
            <VideoCard
                key={hobbyvideo.key}
                path={'hobbyvideos/' + hobbyvideo.key}
                videokey={hobbyvideo.key}
                photoUrl={hobbyvideo.photoUrl}
                name={hobbyvideo.name}
                likes={hobbyvideo.likes || 0}
                likedby={hobbyvideo.likedby || []}
                shares={hobbyvideo.shares || 0}
                timestamp={hobbyvideo.timestamp}
                poster={hobbyvideo.poster}
                video={hobbyvideo.url}
                flagged={hobbyvideo.flagged}
                featured={hobbyvideo.featured}
                age={hobbyvideo.age}
                country={hobbyvideo.country}
                comments={hobbyvideo.comments || 0}
                onLike={this.onLike.bind(this, hobbyvideo, 'hobbyvideos')}
            />
        )
    };

    render() {
        let {hobbyvideos, pageloading} = this.state;
        if(pageloading) {
            return (
                <div className="text-center">
                    <span className="spinner-border text-success" role="status"></span>
                </div>
            );
        }
        let exist = hobbyvideos && hobbyvideos.length > 0 ;
        return (
            <>
                {exist && (
                    <div className="products products--grid products--grid-3 products--grid-overlay flex-row-reverse">
                        {hobbyvideos.map(this.renderHobbyVideo)}
                    </div>
                )}
                {!exist && (
                    <p>{ar.section_empty}</p>
                )}
            </>
        );
    }
}