import React from 'react'
import ParentComponent from '../ParentComponent';
import { AuthContext } from '../AuthContext';
import CommentItem from '../../lib/CommentItem';
import Modal from 'react-modal';
import Utils from '../../lib/Utils';
import { toast } from 'react-toastify';
import {modalStyle} from '../../lib/Styles';
import CommentsSDK from '../CommentsSDK';
import Popover from 'react-popover';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TumblrShareButton,
  TumblrIcon,
} from 'react-share';
import ar from '../../locale';
import moment from 'moment';
import 'moment/locale/ar';
moment.locale('ar');

export default class Field extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            modalIsOpen: false,
            popoverIsOpen: false,
            place: 'above',
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    toggleModal = () => {
        let {user} = this.state;
        if(user) {
            this.state.modalIsOpen = !this.state.modalIsOpen;
            this.updateState();
        } else {
            this.showLoginDependency();
        }
    };

    togglePopover(toState) {
        this.state.popoverIsOpen = typeof toState === "boolean" ? toState : !this.state.popoverIsOpen
        this.updateState();
    }

    onShare = () => {
        this.togglePopover();
    };

    getShareLayout = () => {
        let {shareUrl, shareMessage} = this.props;
        return (
            <div className="d-flex flex-row">
                <FacebookShareButton url={shareUrl} quote={shareMessage + '\n' + shareUrl}>
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareMessage}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={shareMessage}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <TumblrShareButton url={shareUrl} title={shareMessage}>
                    <TumblrIcon size={32} round={true} />
                </TumblrShareButton>
                <EmailShareButton url={shareUrl} body={shareMessage}>
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </div>
        );
    }

    onKey = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            this.onComment();
            e.preventDefault();
        }
    }

    onComment = () => {
        let {user} = this.state;
        if(user) {
            let comment = {
                text: this.comment.value,
                name: user.name,
                photoURL: user.photoURL,
                uid: user.uid,
            };
            CommentsSDK.save(comment,this.props.path);
            this.comment.value='';
        } else {
            this.showLoginDependency();
        }
    };

    renderPlayers = player => {
        let {position} = player;
        return (
            <div key={player.uid} className="position-absolute pad-5" style={{top: position.y, left: position.x}}>
                <div className="player-holder">
                    <div className="cnumber font-weight-bold">
                        {player.number}
                    </div>
                    <img src={player.avatar} className="playersize" alt="player"/>
                    <div className="cnameplate">
                        {player.name}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        let {user} = this.state;
        let {likes, likedby, shares, players, timestamp, featured, comments, avatar, name} = this.props.formation;
        let likedColor = user && likedby && likedby.includes(user.uid) ? 'text-danger' : '' ;
        let playersArr = Utils.convetPropToArray(players);
        return(
            <li className={'product__item fielder card ' + (this.props.className || '')}>
                <div className="posts posts--cards post-grid post-grid--masonry row">
                    <div className="post-grid__item w-100">
                        <div className="posts__item posts__item--card posts__item--category-1 p-3 m-0">
                            <header className="d-flex flex-row-reverse mb-1">
                                <div className="post-author d-flex flex-row-reverse ml-auto">
                                    <div className="mr-0 ml-2">
                                        {avatar && (
                                            <img className="avatar_size" src={avatar} alt="Avatar"/>
                                        )}
                                        {!avatar && (
                                            <img src="../assets/images/blankuser.png" alt="Blank Avatar" className="avatar_size"/>
                                        )}
                                    </div>
                                    <div className="post-author__info">
                                        <h4 className="post-author__name pt-1 m-0 text-white font-weight-bold">{name}</h4>
                                        <span className="posts__date">
                                            <span className="small">
                                                {moment(timestamp).format('ll')}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                {featured && (
                                    <div className="">
                                        <span className="label posts__cat-label">{ar.featured_tag_text_for_formation}</span>
                                    </div>
                                )}
                                {false && (
                                    <div className="">
                                        <span className="mr-2 font_18"><i className="fa fa-ellipsis-h"></i></span>
                                    </div>
                                )}
                            </header>
                            <div className="cfield-con m-auto">
                                <div className="cfield">
                                    {playersArr.map(this.renderPlayers)}
                                </div>
                            </div>
                            <footer className="posts__footer card__footer pb-0 border-0">
                                <ul className="meta d-flex flex_1 justify-content-between">
                                    <li className="meta__item font_14">
                                        <i className={'cursor-pointer meta-like fa fa-heart mr-1 ' + likedColor} onClick={this.props.onLike}></i> 
                                        {likes || 0}
                                    </li>
                                    <li className="meta__item font_14" onClick={this.toggleModal}>
                                        <i className="cursor-pointer meta-like fa fa-comment mr-1"></i> {comments || 0}
                                    </li>
                                    <li className="meta__item meta__item--share font_14">
                                        <Popover 
                                            place={this.state.place} 
                                            isOpen={this.state.popoverIsOpen}
                                            onOuterAction={this.togglePopover.bind(this,false)}
                                            body={this.getShareLayout()}
                                        >
                                            <i className="cursor-pointer meta-like mr-1 fa fa-share-alt" onClick={this.onShare}></i> 
                                        </Popover>
                                        {shares || 0}
                                    </li>
                                </ul>
                            </footer>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    overlayClassName="loader"
                    className="my-modal-md"
                    onRequestClose={this.toggleModal}
                    style={modalStyle}>
                        <div className="position-relative">
                            <i className="position-absolute icon-close font_18 cursor-pointer crosser" onClick={this.toggleModal}></i>
                            <div className="modal_max_height">
                                <CommentItem path={this.props.path} />
                            </div>
                            <div className="input-group form-group flex-row m-0">
                                <input type="text" ref={c => this.comment = c} onKeyUp={this.onKey} className="form-control com-box-bg border-0" placeholder={ar.enter_comments} />
                                <div className="input-group-append">
                                    <button className="btn btn-primary-inverse" type="button" onClick={this.onComment}>{ar.send}</button>
                                </div>
                            </div>
                        </div>
                </Modal>
            </li>
        );
    }
}