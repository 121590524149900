import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Expectations from './expectation';
import SuggestedFormations from './suggestedFormations';
import CreateFormation from './createFormation';
import ar from '../../locale';

export default class EgyptianCoach extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
        };
        
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    render() {
        return (
        <>
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <h1 className="page-heading__title"><span className="highlight">{ar.you_r_trainer}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-content">
                <div className="container">
                    <Tabs>
                        <TabList className="react-tabs__tab-listc d-flex flex_1 flex-row-reverse justify-content-between p-0">
                            <Tab className="react-tabs__tabc flex_1">{ar.change_lineup}</Tab>
                            <Tab className="react-tabs__tabc flex_1">{ar.suggested_formations}</Tab>
                            <Tab className="react-tabs__tabc flex_1">{ar.expectations}</Tab>
                        </TabList>

                        <TabPanel>
                            <CreateFormation {...this.props} />
                        </TabPanel>
                        <TabPanel>
                            <SuggestedFormations {...this.props} />
                        </TabPanel>
                        <TabPanel>
                            <Expectations {...this.props} />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
        );
    }
}