import firebase from '../firebase';

/**
 * Likes of unlikes any firebase end point.
 */
const CommentsSDK = {
  save: (comment, path, successCallback) => {
    let commentsRef = firebase.database().ref('comments/');
    let ref = commentsRef.child(path).push();
    ref
      .set({
        key: ref.key,
        text: comment.text,
        name: comment.name,
        photoUrl: comment.photoURL,
        uid: comment.uid,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      })
      .then(() => {
        let countRef = firebase.database().ref(path);
        countRef.child('comments').transaction(comments => {
          comments = comments || 0;
          comments++;
          return comments;
        });
        if(successCallback){
          successCallback();
        }
      })
      .catch(error => {});
  },

  delete: (comment, path) => {
    let commentsRef = firebase.database().ref('comments/'+ path + '/' + comment.key);
    commentsRef
      .remove()
      .then(() => {
        let countRef = firebase.database().ref(path);
        return countRef.child('comments').transaction(comments => {
          if (comments) {
            comments--;
            return comments;
          } else {
            return 0;
          }
        });
      })
      .catch(error => {
        alert(error.message);
      });
  },

  update: (comment, path) => {
    let commentsRef = firebase.database().ref('comments/'+ path + '/' + comment.key);
    commentsRef.update({
      text: comment.text,
      modified_timestamp: firebase.database.ServerValue.TIMESTAMP,
    })
      .then(() => {
        //do nothing
      })
      .catch(error => {
        alert(error.message);
      });
  },
};

export default CommentsSDK;
