import React from 'react'
import ParentComponent from '../ParentComponent';
import { AuthContext } from '../AuthContext';

export default class EmptyCard extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            modalIsOpen: false,
            popoverIsOpen: false,
            place: 'above',
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        });
    };

    renderCard = (Tagname,index) => {
        return(
            <Tagname key={index+''} className={'product__item crona-bg card d-none d-lg-block'}>
                <div className="posts posts--cards post-grid post-grid--masonry row h-100">
                    <div className="post-grid__item w-100 h-100">
                        <div className="h-100 posts__item posts__item--card posts__item--category-1 m-0 d-flex justify-content-center align-items-center">
                            <span className="rtl fa-flip-horizontal display-1">?</span>
                        </div>
                    </div>
                </div>
            </Tagname>
        );
    }

    render() {
        let { count, tagname}  = this.props;
        var rows = [];
        for (var i = 0; i < count; i++) {
            rows.push(tagname);
        }
        return (
            rows.map(this.renderCard)
        );
    }
}