import React from 'react'
import firebase, {auth, facebookProvider, googleProvider, appleProvider} from '../../lib/firebase';
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Loading from '../../lib/Loading';
import { toast } from 'react-toastify';
import ar from '../../locale';
import {
  NavLink,
  Link,
} from "react-router-dom";

export default class Login extends ParentComponent{
  static contextType = AuthContext;

  constructor(props, context) {
      super(props, context);
      this.state = {
          loading: false,
      };
  }

  updateState = () => {
    this.setState(prev => {
        return {...prev}
    });
  };

  showLoading = show => {
    this.state.loading = show;
    this.updateState();
  };

  loginUsingGoogle = () => {
    this.showLoading(true);
    auth.languageCode = 'ar';
    auth.signInWithPopup(googleProvider).then((result) => {
      this.saveUserInFirebase(result);
    }).catch((error) => { 
      this.showLoading(false);
      this.showError(error.message);
    });
  };
  

  loginUsingFacebook = () => {
    this.showLoading(true);
    facebookProvider.addScope('email');
    auth.languageCode = 'ar';
    auth.signInWithPopup(facebookProvider).then((result) => {
      this.saveUserInFirebase(result);
    }).catch((error) => {
      this.showLoading(false);
      this.showError(error.message);
    });
  }

  loginUsingEmail = (e) => {
    e.preventDefault();
    this.showLoading(true);
    var email = document.getElementsByName("login-email")[0].value;
    var password = document.getElementsByName("login-password")[0].value;
    auth.signInWithEmailAndPassword(email,password).then((result) => {
      this.saveUserInFirebase(result);
    }).catch((error) => {
      this.showLoading(false);
      this.showError(error.message);
    });
  }

  loginUsingApple = e => {
    e.preventDefault();
    this.showLoading(true);
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    appleProvider.setCustomParameters({
      // Localize the Apple authentication screen in French.
      locale: 'ar'
    });
    auth.signInWithPopup(appleProvider).then((result) => {
      this.saveUserInFirebase(result);
    }).catch((error) => {
      this.showLoading(false);
      this.showError(error.message);
    });
  };

  saveUserInFirebase = result => {
    let user = {
      name: result.user.displayName,
      email: result.user.email,
      photoURL: result.user.photoURL,
      uid: result.user.uid,
      contact: null,
      is_contact_verified: false,
      dob: null,
      gender: null,
      club: null,
      noticlub: true,
      notiposts: true,
      notiupdates: true,
    };
    var ref = firebase.database().ref('users/' + user.uid);
    ref
      .once('value', snapshot => {
        if (!snapshot.exists()) {
          ref
            .set(user)
            .then(() => {
              this.showLoading(false);
              this.navigateToPhoneAuth(user);
            })
            .catch(error => {
              this.showLoading(false);
              this.showError(error.message);
            });
        } else {
          this.showLoading(false);
          this.navigateToPhoneAuth(snapshot.val());
        }
      })
      .catch(error => {
        this.showLoading(false);
        this.showError(error.message);
      });
  }

  navigateToPhoneAuth = (user) => {
    let {club,contact,is_contact_verified} = user;
    if(club && contact && is_contact_verified) {
      window.location.href= '/';
    } else {
      window.location.href= '/phoneauth';
    }
  }

  signup = (e) => {
    e.preventDefault();
    var email = document.getElementsByName("register-email")[0].value;
    var password = document.getElementsByName("register-password")[0].value;
    var name = document.getElementsByName("register-name")[0].value;
    if(name && email && password){
      this.showLoading(true);
      firebase
        .auth()
        .createUserWithEmailAndPassword(
          email,
          password,
        )
        .then(result => result.user.updateProfile({ displayName: name}))
        .then(() => auth.signInWithEmailAndPassword(email,password))
        .then((result) => {
          this.saveUserInFirebase(result);
        })
        .catch(error => {
          this.showLoading(false);
          if (error.code === 'auth/email-already-in-use') {
            this.showError(ar.email_already_in_use);
          } else if (error.code === 'auth/invalid-email') {
            this.showError(ar.enter_valid_email);
          } else {
            this.showError(error.message);
          }
          return false;
        });
    } else {
      this.showError(ar.enter_valid_details);
      return false;
    }
  }

  showError = msg => {
      toast.error(msg, {
          position: toast.POSITION.TOP_CENTER
      });
  };

  showSuccess = msg => {
      toast.success(msg, {
          position: toast.POSITION.TOP_CENTER
      });
  };
  
  render() {
    return (
      <div>
          <div className="page-heading">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <h1 className="page-heading__title"><span className="highlight">{ar.login_or_register}</span></h1>
                  <ol className="page-heading__breadcrumb breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">{ar.login_with_credentials}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="site-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card__header">
                      <h4>{ar.login_to_your_account}</h4>
                    </div>
                    <div className="card__content">
                      <form onSubmit={this.loginUsingEmail} autoComplete="off">
                        <div className="form-group">
                          <label htmlFor="login-name">{ar.enter_your_email}</label>
                          <input type="email" name="login-email" className="form-control" placeholder={ar.enter_your_email} required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="login-password">{ar.enter_your_password}</label>
                          <input type="password" name="login-password" className="form-control" placeholder={ar.enter_your_password} required/>
                        </div>
                        <div className="form-group form-group--password-forgot">
                          <label className="checkbox checkbox-inline">
                          </label>
                          <span className="password-reminder"><Link to="/forgot">{ar.forgot_password}</Link></span>
                        </div>
                        <div className="form-group form-group--sm mb-0">
                          <button type="submit" className="btn btn-primary-inverse btn-lg btn-block">{ar.login_to_your_account}</button>
                        </div>
                        <div className="row">
                          <div className="col-md-4 mt-2">
                            <button type="button" className="btn btn-facebook btn-icon btn-block pl-2 pr-2" onClick={this.loginUsingFacebook}><i className="fa fa-facebook"></i> {ar.sign_in_using_facebook}</button>
                          </div>
                          <div className="col-md-4 mt-2">
                            <button type="button" className="btn btn-gplus btn-icon btn-block  pl-2 pr-2" onClick={this.loginUsingGoogle}><i className="fa fa-google"></i> {ar.sign_in_using_google}</button>
                          </div>
                          <div className="col-md-4 mt-2">
                            <button type="button" className="btn bg-black text-white btn-icon btn-block  pl-2 pr-2" onClick={this.loginUsingApple}><i className="fa fa-apple"></i> {ar.sign_in_using_apple}</button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 mt-3 text-center small">
                            <NavLink to="/terms">{ar.terms_conditions_des}</NavLink>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
          
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card__header">
                      <h4>{ar.sign_up_now}</h4>
                    </div>
                    <div className="card__content">
                      <form onSubmit={this.signup} autoComplete="off">
                        <div className="form-group">
                          <label htmlFor="register-name">{ar.enter_valid_name}</label>
                          <input type="text" name="register-name" className="form-control" placeholder={ar.enter_valid_name} required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="register-name">{ar.enter_your_email}</label>
                          <input type="email" name="register-email" className="form-control" placeholder={ar.enter_your_email} required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="register-password">{ar.enter_your_password}</label>
                          <input type="password" name="register-password" className="form-control" placeholder={ar.enter_your_password} required/>
                        </div>
                        <div className="form-group form-group--submit">
                          <button type="submit" className="btn btn-default btn-lg btn-block">{ar.sign_up_now}</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loading show={this.state.loading} />
        </div>
    );
  }
};