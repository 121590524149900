import { subscribeUrl, unsubscribeUrl } from "../../constants";

const Utils = {
    validateName: name => {
      return name != null && name.length > 1;
    },
  
    validateEmail: email => {
      return email != null && /\S+@\S+/.test(email);
    },
  
    validatePassword: password => {
      return password != null && password.length > 5;
    },
  
    validateNumber: number => {
      return number != null && /^\d+$/.test(number);
    },
  
    validateContact: number => {
      return number != null && /^\+?\d+$/.test(number);
    },
  
    validateDob: dob => {
      return dob != null && /^\d{4}-\d{2}-\d{2}$/.test(dob);
    },
  
    validateOTP: number => {
      return number != null && number.length === 6 && /^\d+$/.test(number);
    },
  
    generateComb: (char, times) => {
      let res = '';
      for (var i = 0; i < times; i++) {
        res += char;
      }
      return res;
    },
  
    formatDate: date => {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      return [year, month, day].join('-');
    },
  
    getMediaDetails: media => {
      if (media.type.startsWith('image')) {
        return {filename: media.name, type: 'image'};
      } else if (media.type.startsWith('video')) {
        return {filename: media.name, type: 'video'};
      }
    },
  
    convetPropToArray: messages => {
      let chatArray = [];
      if (messages) {
        let keys = Object.keys(messages);
        if (keys.length > 0) {
          keys.sort();
          keys.forEach(key => {
            let chat = messages[key];
            chat.sent = true;
            chatArray.push(chat);
          });
        }
      }
      return chatArray;
    },
  
    convertToArrayWithInsertionOrder: messages => {
      let chatArray = [];
      if (messages) {
        let keys = Object.keys(messages);
        if (keys.length > 0) {
          keys.forEach(key => {
            let chat = messages[key];
            chat.sent = true;
            chatArray.push(chat);
          });
        }
      }
      return chatArray;
    },
    convertToArrayWithOrderKeyDesc: (messages, orderKey, secondKey) => {
      let chatArray = [];
      if (messages) {
        let keys = Object.keys(messages);
        if (keys.length > 0) {
          keys.forEach(key => {
            let chat = messages[key];
            chatArray.push(chat);
          });
        }
      }
      chatArray.sort((a, b) =>
        a[orderKey] < b[orderKey] ? 1 : b[orderKey] < a[orderKey] ? -1 : 0,
      );
      if(secondKey) {
        chatArray.sort((a, b) =>
          a[secondKey] < b[secondKey] ? 1 : b[secondKey] < a[secondKey] ? -1 : 0,
        );
      }
      return chatArray;
    },
    convertToArrayWithOrderKeyDesc_parsenumber: (messages, orderKey) => {
      let chatArray = [];
      if (messages) {
        let keys = Object.keys(messages);
        if (keys.length > 0) {
          keys.forEach(key => {
            let chat = messages[key];
            chat[orderKey] = parseInt(chat[orderKey]);
            chatArray.push(chat);
          });
        }
      }
      chatArray.sort((a, b) =>
        a[orderKey] < b[orderKey] ? 1 : b[orderKey] < a[orderKey] ? -1 : 0,
      );
      return chatArray;
    },
    convertToArrayWithOrderKeyAsc: (messages, orderKey) => {
      let chatArray = [];
      if (messages) {
        let keys = Object.keys(messages);
        if (keys.length > 0) {
          keys.forEach(key => {
            let chat = messages[key];
            chatArray.push(chat);
          });
        }
      }
      chatArray.sort((a, b) =>
        a[orderKey] > b[orderKey] ? 1 : b[orderKey] > a[orderKey] ? -1 : 0,
      );
      return chatArray;
    },

    subscribeTopic: (authToken, deviceToken, topic, successCallback) => {
      fetch(subscribeUrl, {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authToken,
      },
        body: JSON.stringify({
          topic: topic,
          token: deviceToken,
        }),
      })
      .then(response => {
          if(successCallback) {
            successCallback();
          }
      })
      .catch(error => {
        console.log(error);
      });
    },
    unsubscribeTopic: (authToken, deviceToken, topic, successCallback) => {
      fetch(unsubscribeUrl, {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authToken,
      },
        body: JSON.stringify({
          topic: topic,
          token: deviceToken,
        }),
      })
      .then(response => {
          if(successCallback) {
            successCallback();
          }
      })
      .catch(error => {
        console.log(error);
      });
    },
  };

  export default Utils;
  