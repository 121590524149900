import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import FieldLayout from '../../lib/FieldLayout';
import firebase from '../../lib/firebase';
import LikeSDK from '../../lib/LikeSDK';
import { toast } from 'react-toastify';
import {shareBaseUrl} from '../../constants';
import ar from '../../locale';

export default class EgyptianCoachSingleFormation extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            formation: {},
            pageloading: true,
        };
        this.formationPath = 'clubs_formations/' + this.props.match.params.club +'/' + this.props.match.params.key;
        this.formationsRef = firebase.database().ref(this.formationPath);
    }

    componentDidMount() {
        this.formationsRef.on('value', response => {
            this.state.formation = (response.val());
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.formationsRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    onLike = (item, type) => {
        let {user} = this.state;
        if(user) {
            LikeSDK.like(this.formationsRef)
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            this.showLoginDependency();
        }
    };

    renderFormations = (formation) => {
        let shareUrl =  shareBaseUrl + '/' + this.formationPath;
        return (
            <FieldLayout
                key={formation.key}
                path={this.formationPath}
                className={'m-auto'}
                formation={formation}
                shareUrl={shareUrl}
                shareMessage={''}
                onLike={this.onLike.bind(this, formation, this.formationPath)}
            />
        );
    };

    render() {
        return (
            <div className="site-content">
                <div className="container">
                    {this.state.pageloading && (
                        <div className="text-center">
                            <span className="spinner-border text-success" role="status"></span>
                        </div>
                    )}
                    {!this.state.pageloading && (
                        <ul className="products products--grid products--grid-overlay flex-row-reverse">
                            {this.renderFormations(this.state.formation)}
                        </ul>
                    )}
                </div>
            </div>
        );
    }
}