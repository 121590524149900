import React from 'react'
import {AuthContext} from '../../lib/AuthContext';
import ParentComponent from '../../lib/ParentComponent';
import {auth} from '../../lib/firebase';
import ar from '../../locale';
import {
  NavLink
} from "react-router-dom";
import firebase from '../../lib/firebase';

export default class Header extends ParentComponent{
  
  static contextType = AuthContext;

  constructor(props, context) {
      super(props, context);
      this.state = {
        user: context.user,
        loading: false,
        club: null,
      };
      
  }

  componentDidMount() {
    if (this.state.user) {
      this.userRef = firebase.database().ref('users/' + this.state.user.uid);
      this.userRef.on('value', snapshot => {
        let user = snapshot.val();
        this.state.user = user;
        this.updateState();
        if(user.club) {
          this.clubRef = firebase.database().ref('clubs/' + user.club);
          this.clubRef.on('value', response => {
              this.state.club = response.val();
              this.updateState();
              window.Core.headerNav();
          });
        } else {
          window.Core.headerNav();
        }
      });
    } else {
      window.Core.headerNav();
    }
  }

  componentWillUnmount() {
    if(this.userRef) {
      this.userRef.off('value');
    }
    if(this.clubRef) {
      this.clubRef.off('value');
    }
  }

  updateState = () => {
    this.setState(prev => {
        return {...prev}
    });
  };
  
  signOut = (e) => {
    if(e){
      e.preventDefault();
    }
    auth.signOut().then(() => {
      this.state.club = null;
      this.state.user = null;
      window.location.reload();
    });
    return false;
  }

  loadMyClub = (e) => {
    if(e){
      e.preventDefault();
    }
    this.props.history.push('/my-club');
  };

  loadMyAccount = (e) => {
    if(e){
      e.preventDefault();
    }
    this.props.history.push('/profile');
  };

  preventNav = (e) => {
    if(e){
      e.preventDefault();
    }
  };

  render(){
    let {user, club} = this.state;
    return (
        <>
          <div className="site-overlay"></div>
          <div className="header-mobile clearfix" id="header-mobile">
            <div className="header-mobile__logo">
              <NavLink to="/"><img src="assets/images/football/logo.png" srcSet="assets/images/football/logo@2x.png 2x" alt="Alchemists" className="header-mobile__logo-img" /></NavLink>
            </div>
            <div className="header-mobile__inner">
              { /*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <span id="header-mobile__toggle" className="burger-menu-icon" ><span className="burger-menu-icon__line"></span></span>
            </div>
          </div>

          <header className="header header--layout-2">
            <div className="header__top-bar clearfix">
              <div className="container">
                <div className="header__top-bar-inner">
                  <ul className="nav-account mr-auto ml-0">
                    <li className="nav-account__item"><NavLink to="/profile">{ar.your_account}</NavLink></li>
                    {user && (
                      <li className="nav-account__item"><NavLink to="/notifications">{ar.notifications}</NavLink></li>
                    )}
                    {user && (
                      <li className="nav-account__item nav-account__item--logout"><NavLink to="/logout" className="link" onClick={this.signOut}>{ar.sign_out}</NavLink></li>
                    )}
                    {!user && (
                      <li className="nav-account__item nav-account__item--logout"><NavLink className="link" to="/login">{ar.sign_in}</NavLink></li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="header__secondary">
              <div className="container text-left">
                <ul className="info-block info-block--header rtl">
                  <li className="info-block__item info-block__item--contact-primary">
                    <h6 className="info-block__heading text-lg-left">{ar.join_our_team}</h6>
                    <a className="info-block__link" href="mailto:marketing@egyptiancoach.com">marketing@egyptiancoach.com</a>
                    <img src="assets/images/jointeam.png" className="df-icon df-icon--football-helmet" alt="Join Team"/>
                  </li>
                  <li className="info-block__item info-block__item--contact-secondary">
                    <h6 className="info-block__heading text-lg-left">
                      {user && (
                        <NavLink to="/contact-us">{ar.contact_us}</NavLink>
                      )}
                      {!user && (
                        <span>{ar.contact_us}</span>
                      )}
                    </h6>
                    <a className="info-block__link" href="mailto:info@egyptiancoach.com">info@egyptiancoach.com</a>
                    <img src="assets/images/contact.png" className="df-icon df-icon--football-ball" alt="Contact"/>
                  </li>
                  {user && (
                    <>
                      {club && (
                        <>
                          <li className="info-block__item info-block__item--contact-secondary">
                            <NavLink to="/my-club/rankings" className="p-0 border-bottom-0">
                              <div className="d-inline-block">
                                <h6 className="info-block__heading text-lg-left">{club.name}</h6>
                                <p className="info-block__heading mb-0 p-0 border-bottom-0">
                                  <span>{ar.rankings}</span>
                                </p>
                              </div>
                              <img src="/assets/images/ranking_icon.png" className="df-icon df-icon--football-ball" alt="Blank ranking"/>
                            </NavLink>
                          </li>
                          <li className="info-block__item info-block__item--contact-secondary">
                            <NavLink to="/my-club/matches" className="p-0 border-bottom-0">
                              <div className="d-inline-block">
                                <h6 className="info-block__heading text-lg-left">{club.name}</h6>
                                <p className="info-block__heading mb-0 p-0 border-bottom-0">
                                  <span>{ar.matches}</span>
                                </p>
                              </div>
                              {club.avatar && (
                                <img src={club.avatar} className="circle df-icon df-icon--football-ball" alt="club avatar"/>
                              )}
                            </NavLink>
                          </li>
                        </>
                      )}
                      {!club && (
                        <>
                          <li className="info-block__item info-block__item--contact-secondary">
                            <NavLink to="/my-club/rankings" className="p-0 border-bottom-0">
                              <h6 className="info-block__heading text-lg-left">{ar.clubs}</h6>
                              <p className="info-block__heading mb-0 p-0 border-bottom-0">
                                <span>{ar.rankings}</span>
                              </p>
                            </NavLink>
                          </li>
                          <li className="info-block__item info-block__item--contact-secondary">
                            <NavLink to="/my-club/matches" className="p-0 border-bottom-0">
                              <h6 className="info-block__heading text-lg-left">{ar.clubs}</h6>
                              <p className="info-block__heading mb-0 p-0 border-bottom-0">
                                <span>{ar.matches}</span>
                              </p>
                            </NavLink>
                          </li>
                        </>
                      )}
                      <li className="info-block__item info-block__item--contact-secondary">
                        <NavLink to="/profile" className="p-0 border-bottom-0">
                          <h6 className="info-block__heading text-lg-left">{ar.welcome}</h6>
                          <p className="info-block__heading mb-0 p-0 border-bottom-0">{user.name}</p>
                          {user.photoURL && (
                            <img src={user.photoURL} className="circle df-icon df-icon--football-ball" alt="Profile user"/>
                          )}
                          {!user.photoURL && (
                            <img src="assets/images/blankuser.png" className="circle df-icon df-icon--football-ball" alt="Blank user"/>
                          )}
                        </NavLink>
                      </li>
                    </>
                  )}
                  {!user && (
                    <>
                      <li className="info-block__item info-block__item--contact-secondary">
                        <NavLink to="/my-club/rankings" className="p-0 border-bottom-0">
                          <h6 className="info-block__heading text-lg-left">{ar.clubs}</h6>
                          <p className="info-block__heading mb-0 p-0 border-bottom-0">
                            <span>{ar.rankings}</span>
                          </p>
                          <img src="/assets/images/ranking_icon.png" className="df-icon df-icon--football-ball" alt="Blank ranking"/>
                        </NavLink>
                      </li>
                      <li className="info-block__item info-block__item--contact-secondary">
                        <NavLink to="/my-club/matches" className="p-0 border-bottom-0">
                          <h6 className="info-block__heading text-lg-left">{ar.clubs}</h6>
                          <p className="info-block__heading mb-0 p-0 border-bottom-0">
                            <span>{ar.matches}</span>
                          </p>
                          <img src="assets/images/blank_club.png" className="circle df-icon df-icon--football-ball" alt="Blank club"/>
                        </NavLink>
                      </li>
                      <li className="info-block__item info-block__item--contact-secondary">
                        <h6 className="info-block__heading">{ar.welcome}</h6>
                        <p className="info-block__heading mb-0">{ar.user}</p>
                        <img src="assets/images/blankuser.png" className="circle df-icon df-icon--football-ball" alt="Blank user"/>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>

            <div className="header__primary">
              <div className="container">
                <div className="header__primary-inner">

                  <div className="header-logo">
                    <NavLink to="/"><img src="assets/images/football/logo.png" srcSet="assets/images/football/logo@2x.png 2x" alt="Alchemists" className="header-logo__img" /></NavLink>
                  </div>

                  <nav className="main-nav clearfix rtl">
                    <ul className="main-nav__list">
                      <li className="position-relative">
                        <NavLink exact to="/" activeClassName="active">{ar.home}</NavLink>
                      </li>
                      <li className="position-relative">
                        <NavLink to="/coach" rel="nofollow" className="durunzo" activeClassName="active" onClick={this.preventNav}>{ar.you_r_trainer}</NavLink>
                        <ul className="main-nav__sub">
                          <li className=""><NavLink to="/coach/lineup" activeClassName="active">{ar.change_lineup}</NavLink></li>
                          <li className=""><NavLink to="/coach/suggested-formations" activeClassName="active">{ar.suggested_formations}</NavLink></li>
                          <li className=""><NavLink to="/coach/expectations" activeClassName="active">{ar.expectations}</NavLink></li>
                        </ul>
                      </li>
                      <li className="position-relative">
                        <NavLink to="/chatroom" activeClassName="active">{ar.chatrooms}</NavLink>
                      </li>
                      <li className="position-relative">
                        <NavLink to="/var" activeClassName="active">{ar.egy_var}</NavLink>
                      </li>
                      <li className="position-relative">
                        <NavLink to="/my-hobby" activeClassName="active">{ar.my_hobby}</NavLink>
                      </li>
                      <li className="position-relative">
                        <NavLink to="/feminine" rel="nofollow" className="durunzo" activeClassName="active" onClick={this.preventNav}>{ar.womens_ball}</NavLink>
                        <ul className="main-nav__sub">
                          <li className=""><NavLink to="/feminine/videos" activeClassName="active">{ar.videos}</NavLink></li>
                          <li className=""><NavLink to="/feminine/news" activeClassName="active">{ar.news}</NavLink></li>
                          <li className=""><NavLink to="/feminine/training" activeClassName="active">{ar.training}</NavLink></li>
                        </ul>
                      </li>
                      <li className="position-relative">
                        <NavLink to="/academics" activeClassName="active">{ar.academic_talents}</NavLink>
                      </li>
                      <li className="position-relative">
                        <NavLink to="/awards" activeClassName="active">{ar.egy_coach_awards}</NavLink>
                      </li>
                    </ul>
                  </nav>

                </div>
              </div>
            </div>
          </header>
        </>
      );
  }
};
