import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import firebase from '../../lib/firebase';
import Poll from '../../lib/Poll';
import ar from '../../locale';

export default class EgyptianWar extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            polls: [],
            pageloading: true,
        };
        this.pollsRef = firebase.database().ref('polls/var/');

    }

    componentDidMount() {
        this.pollsRef.on('value', response => {
            this.state.polls = Utils.convertToArrayWithInsertionOrder(response.val());
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.pollsRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    renderPollsView = (polls) => {
        return(
            <Poll
                path="var"
                title={polls.title}
                content={polls.content}
                poster={polls.poster}
                options={polls.options}
                pollkey={polls.key}
                key={polls.key}
                votes={polls.votes}
                image={polls.image}
                video={polls.video}
                closed={polls.closed}
            />
        );
    }

    render() {
        return (
        <>
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <h1 className="page-heading__title"><span className="highlight">{ar.egy_var}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-content">
                <div className="container">
                    {this.state.pageloading && (
                        <div className="text-center">
                            <span className="spinner-border text-success" role="status"></span>
                        </div>
                    )}
                    {!this.state.pageloading && (
                        <ul className="products products--grid products--grid-overlay flex-row-reverse">
                            {this.state.polls.map(this.renderPollsView)} 
                        </ul>
                    )}
                </div>
            </div>
        </>
        );
    }
}