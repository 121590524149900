import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Draggable from 'react-draggable';

export default class DraggablePlayer extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            showName: this.props.showName,
            offset: this.props.offset,
            reset : null,
        };
    }

    componentDidMount() {
        let positionY = this.instance.offsetTop;
        let positionX = this.instance.offsetLeft;
        let position = {
            x: positionX,
            y: positionY,
        }
        this.state.position = position;
        this.updateState();
    }

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    handleStart = (e) => {
        this.state.reset = null;
        this.updateState();
    }

    resetPosition = () => {
        this.state.offset = {x:0, y: 0};
        this.state.reset = {x:0, y: 0};
        this.state.showName = false;
        this.instance.style.transform = 'none';
        this.updateState();
    };

    onDropSuccess = () => {
        this.state.showName = true;
        this.updateState();
    };

    render() {
        let {player} = this.props;
        //let firstname = player.name.split(' ')[0];
        return (
            <Draggable key={player.uid} position={this.state.reset} defaultPosition={this.state.offset} onStart={this.handleStart} onStop={this.props.onStop.bind(this, player, this.state.position, this.resetPosition, this.onDropSuccess)}>
                <div className="position-relative no-select pad-5" ref={(el) => this.instance = el }>
                    <div className="player-holder">
                        <div className="cnumber">
                            {player.number}
                        </div>
                        <img src={player.avatar} className="playersize no-select" draggable="false" alt="player"/>
                        {this.state.showName && (
                            <div className="cnameplate">
                                {player.name}
                            </div>
                        )}
                    </div>
                </div>
            </Draggable>
        );
    }
}