import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import { Redirect } from 'react-router-dom'
import ar from '../../locale';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import Cards from 'react-credit-cards';
import {modalStyle} from '../../lib/Styles';
import firebase, {auth} from '../../lib/firebase';

export default class MyHobbyUpload extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            modalIsOpen: false,
            freeupload: true,
            uploaded: false,
            progress: null,
            user_loaded: false,
        };
        this.mediaRef = firebase.storage().ref('hobbyvideos/');
        this.hobbyRef = firebase.database().ref('hobbyvideos/');
    }

    componentDidMount() {
        let {user} = this.state;
        if(user){
            this.userRef = firebase.database().ref('users/' + user.uid);
            this.userRef.on('value',response => {
                this.state.user = response.val();
                this.state.user_loaded = true;
                this.updateState();
            });
        }
    }

    componentWillUnmount() {
        if(this.userRef){
            this.userRef.off('value');
        }
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    toggleModal = () => {
        this.state.modalIsOpen = !this.state.modalIsOpen;
        this.updateState();
    };

    openFile = () => {
        this.input.click();
    };

    onFileChange = (e) => {
        let media = e.target.files[0];
        this.state.media = media;
        if(media){
            this.state.mediaurl = window.URL.createObjectURL(media);
        } else {
            this.state.mediaurl = null;
        }
        this.updateState();
    };

    uploadNow = () => {
        let {media} = this.state;
        let country = this.country.value;
        let age = this.age.value;
        if(media){
            if (media.size <= 100000000) {
                if (country && age) {
                    this.uploadVideoToStorage(country, age);
                } else {
                    this.showError(ar.provide_country_age);
                }
            } else {
                this.showError(ar.video_too_large);
            }
        } else {
            this.showError(ar.video_first);
        }
    };

    uploadVideoToStorage = (country, age) => {
        let {user} = this.state;
        let key = this.messageIdGenerator();
        let uploadTask = this.mediaRef.child(key).put(this.state.media);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.state.progress = parseFloat(progress.toFixed(3));
            this.updateState();
        }, (error) => {
            // Handle unsuccessful uploads
        }, () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.hobbyRef
                .child(key)
                .update({
                    age: age,
                    country: country,
                    name: user.name,
                    uid: user.uid,
                    key: key,
                    photoUrl: user.photoURL,
                    url: downloadURL,
                    reviewed: false,
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                })
                .then(() => {
                    this.state.uploaded = true;
                    this.state.media = null;
                    this.state.mediaurl = null;
                    this.state.progress = null;
                    this.updateState();
                    toast.success(ar.upload_successful, {
                        position: toast.POSITION.TOP_CENTER
                    });
                })
                .catch(error => {
                    this.state.uploaded = false;
                    this.state.progress = null;
                    this.updateState();
                });
            });
        });
    };

    showError = msg => {
        toast.error(msg, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    messageIdGenerator = () => {
        return this.hobbyRef.push().key;
    };

    
    render() {
        let {user_loaded, user, freeupload} = this.state;
        if(!user) {
            return <Redirect to="/my-hobby/consent" />;
        } else {
            if(user_loaded){
                if(user.credits > 0 || freeupload){
                    return (
                        <div className="site-content">
                            <div className="container text-center">
                                <div className="row">
                                    <div className="col-md-6 mt-2">
                                        {this.state.mediaurl && (
                                            <div className="">
                                                <Player src={this.state.mediaurl}></Player>
                                            </div>
                                        )}
                                        {!this.state.mediaurl && (
                                            <div className="bonkai">
                                                <div><i className="fa fa-video fa-5x"></i></div>
                                                <h3 className="mt-3">{ar.see_preview}</h3>     
                                            </div>
                                        )}
                                        {this.state.progress && this.state.progress>0 &&(
                                            <div className="progress mt-2">
                                                <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" style={{width: this.state.progress + '%'}}></div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 text-center mt-2">
                                        <input ref={e => {this.input = e}} type="file" className="d-none" multiple={false} accept="video/*" onChange={this.onFileChange}/>
                                        <button className="btn btn-primary w250" onClick={this.openFile}>
                                            <i className="fa fa-video"></i>
                                            <span className="pl-3">{ar.tap_to_select}</span>
                                        </button>
                                        <div className="mt-4 w250 mr-auto ml-auto">
                                            <input type="text" ref={e => {this.country = e}} className="form-control mt-2" name="country" placeholder={ar.country} />
                                            <input type="number" ref={e => {this.age = e}} className="form-control mt-2" name="age" maxLength="2" placeholder={ar.age} />
                                            <button className="btn btn-primary-inverse w250 mt-2" onClick={this.uploadNow}>
                                                {ar.upload_now}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return <Redirect to="/my-hobby/consent" />;
                }
            } else {
                return null;
            }
        }
    }
}