import React from 'react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
//import Video from 'react-native-video';

export default class MessageVideo extends React.Component {
  constructor(props,context){
    super(props);
  }

  render() {
    return (
      <div style={{width: 300, borderTopRightRadius: 13, overflow: 'hidden'}}>
        <Player
          src={this.props.currentMessage.video}
          poster={this.props.currentMessage.poster}></Player>
      </div>
    );
  }
}
