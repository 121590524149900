import React from 'react'
import firebase, {auth} from '../../lib/firebase';
import { AuthContext } from '../../lib/AuthContext';
import ParentComponent from '../../lib/ParentComponent';
import { Slide } from 'react-slideshow-image';
import Utils from '../../lib/Utils';
import UpcomingMatches from '../MyClub/upcomingMatches';
import SuggestedFormations from '../EgyptianCoach/suggestedFormations';
import HobbyList from '../MyHobby/hobbyList';
import ar from '../../locale';
import {
  NavLink
} from "react-router-dom";

export default class Home extends ParentComponent{
  static contextType = AuthContext;

  constructor(props, context) {
      super(props, context);
      this.state = {
          user: context.user,
          featured_formations: [],
          featured_hobby: [],
          slideshow: [],
          slideshow_index: 0,
      };
      this.slideshowRef = firebase.database().ref('slideshow/');
  }

  componentDidMount() {
    this.slideshowRef.once('value', response => {
      this.state.slideshow.length = 0;
      this.state.slideshow = Utils.convetPropToArray(response.val());
      this.updateState();
      window.Core.SlickCarousel();
    });
  }

  componentWillUnmount() {}

  updateState = () => {
    this.setState(prev => {
        return {...prev}
    });
  };

  loadSlideshow = (slide, e) => {
    e.preventDefault();
    this.props.history.push('slideshow/'+slide.key);
    return false;
  };

  renderSlide = (slide) => {
    return(
      <div className="each-slide" key={slide.key}>
        <div className="slide-image" style={{'backgroundImage': `url(${slide.image})`}}>
          <div className="w-100 h-100 p-5 blacktint d-flex flex-column flex-fill justify-content-center align-items-center">
              <h1 className="mb-0 text-center">{slide.title}</h1>
              <h5 className="m-0 text-center text-success">{slide.subtitle}</h5>
              <button type="button" className="mt-3 btn btn-primary-inverse btn-sm" onClick={this.loadSlideshow.bind(this,slide)}>
                {ar.know_more}
              </button>
          </div>
        </div>
      </div>
    );
  };

  renderSlideTemplate = (slide, index) => {
    return(
        <div key={index+''} className="slick__slide text-center">
          <div className="posts__item  posts__item--category-2 position-relative slide-image page-heading p-0" style={{'backgroundImage': `url(${slide.image})`}}>
            <div className="w-100 h-100 tinter">
              <div className="posts__inner">
                {/* <div className="posts__cat text-center">
                  <span className="label posts__cat-label">Injuries</span>
                </div> */}
                <h3 className="text-center">
                  <a href="#" className="text-center text-white" onClick={this.loadSlideshow.bind(this,slide)}>
                    <p className="text-center mb-0">{slide.title}</p>
                    <p className="posts__title-higlight text-center mb-0 mt-1">{slide.subtitle}</p>
                    <button type="button" className="mt-3 btn btn-primary-inverse btn-sm">
                      {ar.know_more}
                    </button>
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
    );
  };

  render() {
    let {user} = this.state;
    return (
      <>
        {this.state.slideshow.length > 0 && true && (
          <div className="position-relative">
            <div className="slick posts posts-slider posts--slider-top-news">
              {this.state.slideshow.map(this.renderSlideTemplate)}
            </div>
          </div>
        )}
        {this.state.slideshow.length > 0 && false && (
          <div className="position-relative">
            <Slide duration={5000} indicators={true} arrows={false} transitionDuration={300}>
              {this.state.slideshow.map(this.renderSlide)}
            </Slide>
          </div>
        )}
        <div>
            <div>
              <div className="mt-0">
                <UpcomingMatches hidebanner={true}/>
              </div>
              {user && (
                <div className="site-content pt-0">
                  <div className="container">
                    <div className="d-flex flex-row blexer mb-3">
                      <h6 className="mb-2"><NavLink to="/coach/suggested-formations">{ar.more}</NavLink></h6>
                      <h6 className="mb-2 flex-fill">{ar.featured_formations}</h6>
                    </div>
                    <SuggestedFormations featured={true} hidebanner={true}/>
                  </div>
                </div>
              )}
              <div className="site-content pt-0">
                <div className="container">
                  <div className="d-flex flex-row blexer mb-3">
                    <h6 className="mb-2"><NavLink to="/my-hobby">{ar.more}</NavLink></h6>
                    <h6 className="mb-2 flex-fill">{ar.featured_hobby}</h6>
                  </div>
                  <HobbyList featuredonly={true} />
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }

}