import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import ar from '../../locale';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import Cards from 'react-credit-cards';
import {modalStyle} from '../../lib/Styles';
import {auth} from '../../lib/firebase';
import 'react-credit-cards/es/styles-compiled.css';
const functionURL =
  'https://us-central1-egyptiancoach-6a357.cloudfunctions.net/app';
const payUrl = functionURL + '/payWithStripe';
export default class MyHobbyConsent extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            freeupload: true,
            modalIsOpen: false,
            loading: false,
            card: {
                cvc: '',
                expiry: '',
                name: '',
                number: '',
            },
        };
    }

    componentDidMount() {
        let {user} = this.state;
        if (user) {
            auth.currentUser.getIdToken()
            .then(token => {
                this.state.token = token;
                this.updateState();
            });
        }
    }

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    toggleModal = () => {
        this.state.modalIsOpen = !this.state.modalIsOpen;
        this.updateState();
    }

    onClickUpload = () => {
        let {user,freeupload} = this.state;
        if(user) {
            if (freeupload) {
                this.props.history.push('/my-hobby/upload');
            } else {
                this.toggleModal();
            }
        } else {
            this.showLoginDependency();
        }
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.state.card[name] = value;
        this.updateState();
    };

    //validate ,show loader and confirm the payment
    confirmPayment = () => {
        let {name, number, cvc, expiry}  = this.state.card;
        if(name && number && cvc && expiry) {
            this.state.loading = true;
            this.updateState();
            let {card} = this.state;
            let expMonthYearArr = expiry.split('/');
            card.exp_month = parseInt(expMonthYearArr[0]);
            card.exp_year = parseInt(expMonthYearArr[1]);
            let params = {...card};
            delete params.expiry;
            window.Stripe.card.createToken(params, this.stripeResponseHandler);
        } else {
            toast.error(ar.enter_valid_details,{
                position: toast.POSITION.TOP_CENTER
            });
        }
    };

    stripeResponseHandler = (status, response) => {
        if (response.error) { 
            this.state.loading = false;
            this.updateState();
            toast.error(response.error.message,{
                position: toast.POSITION.TOP_CENTER
            });
        } else {
            // Get the token ID:
            var token = response.id;
            fetch(payUrl, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.state.token,
                    },
                    body: JSON.stringify({ token: token}),
                })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === 'succeeded') {
                        this.state.loading = false;
                        this.toggleModal();
                        //load video upload form
                        this.props.history.push('/my-hobby/upload');
                    }
                })
                .catch(error => {
                    this.state.loading = false;
                    this.updateState();
                    toast.error(error.message,{
                        position: toast.POSITION.TOP_CENTER
                    });
                });

        }
    }

    render() {
        let {name, number, cvc, expiry}  = this.state.card;
        return (
            <>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <h1 className="page-heading__title"><span className="highlight">{ar.my_hobby}</span></h1>
                                <h4 className="mt-1 text-center">{ar.upload_video}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-content">
                    <div className="container text-center">
                        <img src="/assets/images/upload.png" width="100" alt="upload" />
                        <h1 className="mt-5 text-center">
                            {ar.upload_consent_heading}
                        </h1>
                        <h6 className="mt-2 text-center">
                            {ar.upload_consent_des}
                        </h6>
                        <button type="button" className="mt-2 btn btn-primary-inverse" onClick={this.onClickUpload}>
                            {ar.upload_now}
                        </button>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    overlayClassName="loader"
                    className="my-modal-card"
                    onRequestClose={this.toggleModal}
                    style={modalStyle}>
                        <div className="position-relative">
                            <i className="position-absolute icon-close font_18 cursor-pointer crosser" onClick={this.toggleModal}></i>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <Cards
                                        cvc={cvc}
                                        expiry={expiry}
                                        name={name}
                                        number={number}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col">
                                        <form action="" autoComplete="off">
                                            <input
                                                type="number"
                                                name="number"
                                                placeholder={ar.card_number}
                                                className="form-control border-0 com-box-bg"
                                                required
                                                onChange={this.handleInputChange}
                                            />
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder={ar.holder_name}
                                                className="form-control border-0 com-box-bg mt-1"
                                                required
                                                onChange={this.handleInputChange}
                                            />
                                            <div className="d-flex flex-row mt-1">
                                               <input
                                                    type="text"
                                                    name="expiry"
                                                    placeholder="MM / YY"
                                                    className="form-control border-0 com-box-bg"
                                                    required
                                                    onChange={this.handleInputChange}
                                                /> 
                                                <input
                                                    type="number"
                                                    name="cvc"
                                                    maxLength="3"
                                                    placeholder={ar.cvc}
                                                    className="form-control border-0 com-box-bg ml-1"
                                                    required
                                                    onChange={this.handleInputChange}
                                                /> 
                                            </div>
                                            <div className="d-flex flex-row mt-4 justify-content-between">
                                                <button className="btn btn-warning" type="button" onClick={this.toggleModal}>{ar.cancel}</button>
                                                {!this.state.loading && (
                                                    <button className="btn btn-primary" type="button" onClick={this.confirmPayment}>{ar.confirm}</button>
                                                )}
                                                {this.state.loading && (
                                                    <button className="btn btn-primary" type="button">
                                                        <div className="spinner-border spinner-border-sm text-success" role="status"></div>
                                                    </button>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Modal>
            </>
        );
    }
}