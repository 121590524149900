import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import FieldLayout from '../../lib/FieldLayout';
import firebase from '../../lib/firebase';
import LikeSDK from '../../lib/LikeSDK';
import { toast } from 'react-toastify';
import {shareBaseUrl} from '../../constants';
import ar from '../../locale';
import EmptyCard from '../../lib/EmptyCard';

export default class SuggestedFormations extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            formations: [],
            pageloading: true,
        };
        
    }

    componentDidMount() {
        if (this.state.user) {
            this.formationPath = 'clubs_formations/' + this.state.user.club;
            this.formationsRef = firebase.database().ref(this.formationPath);
            if(this.props.featured){
                this.formationsRef.orderByChild('featured').equalTo(true).on('value', this.handleData);
            }else{
                this.formationsRef.orderByChild('timestamp').limitToLast(150).on('value',this.handleData);
            }
        }
    }

    handleData = response => {
        let featuredData = [];
        let otherData = []
        let formations = Utils.convertToArrayWithOrderKeyDesc(
            response.val(),'on'
        );
        formations.forEach(item => {
            if(item.featured){
                featuredData.push(item);
            } else {
                otherData.push(item);
            }
        });
        this.state.pageloading = false;
        this.state.formations = [...featuredData, ...otherData];
        this.updateState();
    };

    componentWillUnmount() {
        if(this.formationsRef){
            this.formationsRef.off('value');
        }
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    onLike = (item, type) => {
        let ref = this.formationsRef.child(item.key);
        LikeSDK.like(ref)
        .then(response => {
            //Don't do anything, UI will be updated by on value listener
        })
        .catch(error => {
            toast.error(error.message,{
                position: toast.POSITION.TOP_CENTER
            });
        });
    };

    renderFormations = (formation) => {
        let shareUrl = shareBaseUrl + '/' + this.formationPath + '/' + formation.key;
        return (
            <FieldLayout
                key={formation.key}
                path={this.formationPath+'/'+formation.key}
                formation={formation}
                shareUrl={shareUrl}
                shareMessage={''}
                onLike={this.onLike.bind(this, formation, this.formationPath)}
            />
        );
    };

    renderLoginContent = () => {
        return (
            <div className="site-content">
                <div className="container">
                    <h3>{ar.login_to_access}</h3>
                </div>
            </div>
        );
    }

    render() {
        let {formations, user} = this.state;
        let {hidebanner} = this.props;
        let zeropad = hidebanner ? 'p-0' : '';
        if (user) {
            return (
                <>
                    {!hidebanner && (
                        <div className="page-heading">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <h1 className="page-heading__title"><span className="highlight">{ar.you_r_trainer}</span></h1>
                                        <h4 className="mt-1 text-center">{ar.suggested_formations}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={'site-content '+zeropad}>
                        <div className="container">
                            {this.state.pageloading && (
                                <div className="text-center">
                                    <span className="spinner-border text-success" role="status"></span>
                                </div>
                            )}
                            {!this.state.pageloading && (
                                <ul className="products products--grid products--grid-overlay flex-row-reverse">
                                    {formations.map(this.renderFormations)} 
                                </ul>
                            )}
                        </div>
                    </div>
                </>
            );
        } else {
            return this.renderLoginContent();
        }
    }
}