import React from 'react'
import ParentComponent from '../ParentComponent';
import firebase from '../../lib/firebase';
import Utils from '../../lib/Utils';
import { AuthContext } from '../AuthContext';
import ar from '../../locale';
import moment from 'moment';
import 'moment/locale/ar';
import CommentSingleItem from '../CommentSingleItem';
moment.locale('ar');

export default class CommentItem extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            comments: [],
        };
        this.commentPath = 'comments/'+this.props.path;
        this.commentRef = firebase.database().ref(this.commentPath);
    }

    componentDidMount() {
        this.commentRef
        .orderByChild('timestamp')
        .limitToLast(60)
        .on('value', response => {
            let comments = Utils.convetPropToArray(response.val());
            this.state.comments = comments;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.commentRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    renderComment = (comment) => {
        return(
            <CommentSingleItem path={this.props.path} comment={comment} />
        );
    };

    render() {
        return (
            <>
                {this.state.comments.map(this.renderComment)}
                {this.state.comments.length === 0 && (
                    <li className="p-3 list-unstyled">
                        <div>{ar.section_empty}</div>
                    </li>
                )}
            </>
        );
    }
}