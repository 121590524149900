import React from 'react'
import ParentComponent from '../ParentComponent';
import { AuthContext } from '../AuthContext';
import ar from '../../locale';
import CommentsSDK from '../CommentsSDK';
import moment from 'moment';
import 'moment/locale/ar';
moment.locale('ar');

export default class CommentSingleItem extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            editopen: false,
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    };

    cancelComment = (comment) => {
       this.state.editopen = false;
       this.updateState();
    }

    deleteComment = (comment) => {
        CommentsSDK.delete(comment, this.props.path);
    };

    editComment = (comment) => {
        this.state.editopen = true;
        this.commentbox.focus();
        this.updateState();
    };

    updateComment = (comment) => {
         let newtext = this.commentbox.textContent;
         if (newtext) {
             comment.text = newtext;
             CommentsSDK.update(comment, this.props.path);
             this.state.editopen = false;
         }        
    };

    render() {
        let {user} = this.state;
        let {comment} = this.props;
        return(
            <div key={comment.key} className="widget__content card__content p-2">
                <div className="comments-list__item comments-list__item--category-1 border_bottom_ex">
                    <header className="comments-list__header d-flex flex-row-reverse p-0 mb-0">
                            <figure className="ml-2">
                                {comment.photoUrl && (
                                    <img src={comment.photoUrl} alt="" className="avatar_size"/>
                                )}
                                {!comment.photoUrl && (
                                    <img src="../assets/images/blankuser.png" alt="" className="avatar_size"/>
                                )}
                            </figure>
                            <div className="comments-list__info">
                                <h5 className="comments-list__author-name com-ti-color">{comment.name}</h5>
                                <span className="comments-list__date small">{moment(comment.timestamp).format('ll')}</span>
                            </div>
                        <div className="mr-auto">
                            {user && user.uid === comment.uid && (
                                <p className="small">
                                    <span className="cursor-pointer pr-2 pl-2" onClick={this.deleteComment.bind(this,comment)}>{ar.delete}</span>
                                    {!this.state.editopen && (
                                        <span className="cursor-pointer pr-2 pl-2" onClick={this.editComment.bind(this,comment)}>{ar.edit}</span>
                                    )}
                                    {this.state.editopen && (
                                        <>
                                            <span className="cursor-pointer pl-2" onClick={this.updateComment.bind(this,comment)}>{ar.save}</span>
                                            <span className="cursor-pointer pl-2" onClick={this.cancelComment.bind(this,comment)}>{ar.cancel}</span>
                                        </>
                                    )}
                                </p>
                            )}
                        </div>
                    </header>
                    <div className="comments-list__body">
                        <p ref={c => this.commentbox = c} className={'mb-0 pt-1 pb-1 com-te-color ' + (this.state.editopen ? 'bg-light': '')} contentEditable={this.state.editopen}>{comment.text}</p>
                    </div>
                </div> 
            </div>
        );
    };
}