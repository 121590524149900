import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import ar from '../../locale';
import moment from 'moment';
import 'moment/locale/ar';
moment.locale('ar');

export default class Matches extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            popoverIsOpen: false,
            place: 'above',
        };
    }

    componentDidMount() {
       
    }

    componentWillUnmount() {
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    render() {
        let {on, started, started_timestamp,team1_venue,team2_venue,venue, team1_score, team2_score, team1_avatar, team2_avatar, team1_name, team2_name} = this.props;
        let styled = {
            team1: {},
            team2: {},
        };
        if (team1_score !== team2_score) {
            if (team1_score > team2_score) {
                styled.team1 = 'text-success';
                styled.team2 = 'text-danger';
            }
            if (team2_score > team1_score) {
                styled.team2 = 'text-success';
                styled.team1 = 'text-danger';
            }
        }
        return (
            <div className="game-result">
                <section className="game-result__section pt-0">
                    <header className="game-result__header game-result__header--alt">
                        <span className="game-result__league"></span>
                        <h3 className="game-result__title"><span className="highlight">{ar.kickoff} {moment(on).format('h:mm a')}</span></h3>
                        <span className="game-result__date">{moment(on).format('Do MMMM')}</span>
                    </header>
                    <div className="game-result__content">
                        <div className="game-result__team game-result__team--first">
                            <img src={team1_avatar} alt="Team 1" className="game-result__team-logo"/>
                            <div className="game-result__team-info">
                                <h5 className="game-result__team-name text-center">{team1_name}</h5>
                                {/* <div className="game-result__team-desc text-center">{team1_venue}</div> */}
                            </div>
                        </div>
                        <div className="game-result__score-wrap">
                            <div className="game-result__score game-result__score--lg text-center">
                                <span className={'game-result__score-result game-result__score-result--winner ' + styled.team1}>{team1_score}</span> 
                                <span className="game-result__score-dash">-</span> 
                                <span className={'game-result__score-result game-result__score-result--loser ' + styled.team2}>{team2_score}</span>
                            </div>
                        </div>
                        <div className="game-result__team game-result__team--second">
                            <img src={team2_avatar} alt="Team 2" className="game-result__team-logo"/>
                            <div className="game-result__team-info">
                                <h5 className="game-result__team-name text-center">{team2_name}</h5>
                                {/* <div className="game-result__team-desc text-center">{team2_venue}</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="laster">&nbsp;</div>
                </section>
            </div>
        )
    }

    render2() {
        let {on, started, started_timestamp, team1_score, team2_score, team1_avatar, team2_avatar, team1_name, team2_name} = this.props;
        let styled = {
            team1: {},
            team2: {},
        };
        if (team1_score !== team2_score) {
            if (team1_score > team2_score) {
                styled.team1 = 'text-success';
                styled.team2 = 'text-danger';
            }
            if (team2_score > team1_score) {
                styled.team2 = 'text-success';
                styled.team1 = 'text-danger';
            }
        }
        return (
            <div className="product__item product__item--color-2 card noBorder backgroundCard">
                <div className="d-flex flex-row p-3">
                    <div className="flex_0 d-flex flex-column justify-content-center align-items-center">
                        <img src={team1_avatar} alt="team logo" className="match_logo"/>
                        <p className="mb-0 textcenter">{team1_name}</p>
                    </div>
                    <div className="flex_1 d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <p className={'background_white border-radius5 mb-0 px-3 ml-1 ' + styled.team1}>{team1_score}</p>
                            <p className={'background_white border-radius5 mb-0 px-3 ' + styled.team2}>{team2_score}</p>
                        </div>
                        <p className="mb-0 small">{moment(on).format('MMM Do, h:mm a')}</p>
                    </div>
                    <div className="flex_0 d-flex flex-column justify-content-center align-items-center">
                        <img src={team2_avatar} alt="team logo" className="match_logo"/>
                        <p className="mb-0 textcenter">{team2_name}</p>
                    </div>
                </div>
            </div>
        );
    }
}