import React from 'react'
import ParentComponent from '../ParentComponent';
import { AuthContext } from '../AuthContext';

export default class Loading extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateState = () => {};

    render() {
        if (this.props.show) {
            return (
                <div id="c_loader" className="loader"><div className="spinner-border text-success" role="status"></div></div>
            );
        }
        else {
            return null;
        }
    }
}