import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import Loading from '../../lib/Loading';
import {auth} from '../../lib/firebase';
import { toast } from 'react-toastify';
import ar from '../../locale';
import {
  Link
} from "react-router-dom";

export default class ForgotPassword extends ParentComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            email: '',
            mailSent: false,
            loading: false,
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateValue = (key, value) => {
      this.state[key] =value.target.value;
      this.updateState();
    };

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoading = show => {
        this.state.loading = show;
        this.updateState();
    };

    resetMail = (e) => {
        e.preventDefault();
        this.showLoading(true);
        auth.sendPasswordResetEmail(this.state.email).then(() => {
            this.showLoading(false);
            toast.success(ar.reset_success_msg, {
                position: toast.POSITION.TOP_CENTER
            });
        }).catch((error) => {
            this.showLoading(false);
           toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
           });
        });
    };


    render() {
        
        return (
            <div className="site-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">

                            <div className="card">
                                <div className="card__header">
                                    <h4>{ar.forgot_password}</h4>
                                </div>
                                <div className="card__content">
                                    <form action="#" autoComplete="off" onSubmit={this.resetMail}>
                                        <div className="form-group">
                                            <label htmlFor="register-name">{ar.enter_your_email}</label>
                                            <input type="email" disabled={this.state.mailSent} defaultValue={this.state.email} onChange={this.updateValue.bind(this,'email')} className="form-control" placeholder={ar.enter_valid_email} required />
                                        </div>
                                        <div className="form-group form-group--password-forgot">
                                            <label className="checkbox checkbox-inline">
                                            </label>
                                            <span className="password-reminder"><Link to="/login">{ar.already_have_an_account}</Link></span>
                                        </div>
                                        <div className="form-group form-group--submit">
                                            <button type="submit" className="btn btn-default btn-lg btn-block">{ar.reset}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Loading show={this.state.loading} />
            </div>
        );
    }
}