import firebase, {auth} from '../firebase';

/**
 * Likes of unlikes any firebase end point.
 * Path is for updating the share count on that end point
 */
const ShareSDK = {
  share: async (item, path) => {
    console.log('shared', path);
  }
};

export default ShareSDK;