import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { Redirect } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import firebase, {auth} from '../../lib/firebase';
import CustomDateInput from '../../lib/CustomDateInput';
import ar from '../../locale';
import moment from 'moment';
import Loading from '../../lib/Loading';
import { toast } from 'react-toastify';
moment.locale('en');

const genderOptions = [
    { value: 'male', label: ar.male },
    { value: 'female', label: ar.female },
];

export default class PhoneAuth extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        //Set default values in context user when not available
        this.state = {
            user: context.user,
            clubs: [],
            clubsObj: {},
            otpSent: false,
            otpSending: false,
            loading: false,
        };
        if (
        !(
            this.state.user.contact &&
            this.state.user.is_contact_verified
        )
        ) {
            this.state.show_contact = true;
        }
        this.clubRef = firebase.database().ref('/clubs');
        //auth.currentUser.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID);
    }

    componentDidMount() {
        this.clubRef.on('value', response => {
            let clubsObj = response.val();
            let clubs = Utils.convertToArrayWithOrderKeyAsc(clubsObj, 'order');
            if (clubs) {
                clubs.forEach(club => {
                    let obj = {
                        value : club.key,
                        label: club.name,
                    }
                    this.state.clubs.push(obj);
                })
                this.state.clubsObj = clubsObj;
                this.updateState();
            }
        });
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
        });
    }

    componentWillUnmount() {
        this.clubRef.off('value');
    }

    updateValue = (key, value) => {
        if(key === 'dob'){
            let dob = moment(value);
            dob.locale('en');
            let modifiedDate = dob.format('YYYY-MM-DD');
            this.state.user[key] = modifiedDate;
        } else if(key === 'contact' || key === 'otp'){
            this.state.user[key] = value.target.value;
        } else if(key === 'gender' || key === 'club'){
            this.state.user[key] = value.value;
            if (key === 'club') {
                this.state.user.club_name = this.state.clubsObj[value.value].name;
            }
        } else{
            this.state.user[key] =value;
        }
      this.updateState();
    };

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoading = show => {
        this.state.loading = show;
        this.updateState();
    };

    save = (e) => {
        e.preventDefault();
        if (this.state.user.club == null) {
            this.showError(ar.enter_valid_club);
        } else if (!Utils.validateContact(this.state.user.contact)) {
            this.showError(ar.enter_valid_number);
        } else {
            //Everything is successful, send him OTP
            this.showLoading(true);
            if (this.state.show_contact) {
                //Add +2 sign before contact if not present
                if (!this.state.user.contact.startsWith('+')){
                    this.state.user.contact = '+2' + this.state.user.contact
                }
                const appVerifier = window.recaptchaVerifier;
                auth
                .currentUser
                .linkWithPhoneNumber(this.state.user.contact,appVerifier)
                .then( confirmResult => {
                    this.state.otpSent = true;
                    this.state.otpSending = false;
                    window.confirmResult = confirmResult;
                    this.showSuccess(ar.otp_sent_msg);
                    this.showLoading(false);
                })
                .catch(error => {
                    this.state.otpSent = false;
                    this.state.otpSending = false;
                    this.showLoading(false);
                    if(error.code === 'auth/invalid-phone-number') {
                        this.showError(ar.invalid_contact_description);
                    } else {
                        this.showError(error.message);
                    }
                });
            } else {
                this.updateUserDetails();
            }
        }
    };

    verifyOTP = () => {
        this.showLoading(true);
        window.confirmResult.confirm(this.state.user.otp).then(result => {
            this.updateUserDetails();
        }).catch( error => {
            this.showLoading(false);
            this.showError(error.message);
        });
    };

    updateUserDetails = () => {
        delete this.state.user.credits;
        delete this.state.user.otp;
        firebase
            .database()
            .ref('users/'+this.state.user.uid)
            .update({
                gender: this.state.user.gender || null,
                club: this.state.user.club,
                club_name: this.state.user.club_name,
                dob: this.state.user.dob || null,
                contact: this.state.user.contact,
                is_contact_verified: true,
            })
            .then(res => {
                this.showLoading(false);
                window.location.href = '/';
            })
            .catch(error => {
                this.showLoading(false);
                this.showError(error.message);                
            });
    };

    showError = msg => {
        toast.error(msg, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    showSuccess = msg => {
        toast.success(msg, {
            position: toast.POSITION.TOP_CENTER
        });
    };

    render() {
        if (!this.state.user) {
            return <Redirect to="/login" />;
        } else {
            return (
                <div className="site-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <div className="card">
                                    <div className="card__header">
                                        <h4>{ar.additional_details}</h4>
                                    </div>
                                    <div className="card__content">

                                        <form autoComplete="off" onSubmit={() => {return false}}>
                                            <div className="form-group">
                                                <label htmlFor="register-name">{ar.enter_valid_gender}</label>
                                                <Dropdown options={genderOptions} onChange={this.updateValue.bind(this,'gender')} value={this.state.user.gender} />
                                            </div>
                                            <div className="form-group">
                                                <label>* {ar.enter_valid_club}</label>
                                                <Dropdown options={this.state.clubs} onChange={this.updateValue.bind(this,'club')} value={this.state.user.club} placeholder={ar.select_club} />
                                            </div>
                                            <div className="form-group">
                                                <label>{ar.birthdate}</label>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={this.state.user.dob ? moment(this.state.user.dob,"YYYY-MM-DD").toDate() : null}
                                                    onChange={this.updateValue.bind(this,'dob')}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={<CustomDateInput />}
                                                />
                                            </div>
                                            {this.state.show_contact && (
                                                <div className="form-group">
                                                    <label>* {ar.enter_valid_number}</label>
                                                    <input type="text" disabled={this.state.otpSent} name="register-contact" value={this.state.user.contact} onChange={this.updateValue.bind(this,'contact')} className="form-control" placeholder={ar.enter_valid_number} required />
                                                </div>
                                            )}
                                            {!this.state.otpSent && (
                                                <div className="form-group form-group--submit">
                                                    <button type="button" onClick={this.save} className="btn btn-default btn-lg btn-block">{ar.save}</button>
                                                </div>
                                            )}
                                            {this.state.otpSent && (
                                                <div>
                                                    <p className="text-center cursor-pointer" onClick={this.save}>( {ar.resend} )</p>
                                                    <div className="form-group">
                                                        <label >{ar.send_otp}</label>
                                                        <input type="text" name="register-contact" onChange={this.updateValue.bind(this,'otp')} className="form-control" placeholder={ar.enter_valid_otp} required />
                                                    </div>
                                                    <div className="form-group form-group--submit">
                                                        <button type="button" onClick={this.verifyOTP} className="btn btn-default btn-lg btn-block">{ar.verify_otp}</button>
                                                    </div>
                                                </div>
                                            )}
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Loading show={this.state.loading} />
                </div>
            );
        }
    }
}