import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import Home from './home';

export default class Academics extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
        };
        
    }

    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        return <Home {...this.props} />;
    }

}