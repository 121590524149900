import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import 'react-dropdown/style.css'
import { AuthContext } from '../../lib/AuthContext';
import firebase from '../../lib/firebase';
import LikeSDK from '../../lib/LikeSDK';
import {shareBaseUrl} from '../../constants';
import { toast } from 'react-toastify';
import Popover from 'react-popover';
import { Player } from 'video-react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TumblrShareButton,
  TumblrIcon,
} from 'react-share';
import ar from '../../locale';
import moment from 'moment';
import 'moment/locale/ar';
moment.locale('ar');

export default class FeminineVideoDetail extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            videos: {
                likedby:[],
            },
            popoverIsOpen: false,
            place: 'above',
            pageloading: true,
        };
        this.videosRef = firebase.database().ref('videos/' + this.props.match.params.key);
        
    }

    componentDidMount() {
        this.videosRef.on('value', response => {
            this.state.videos = response.val();
            this.state.videos.likedby = this.state.videos.likedby || [];
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.videosRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    like = (videos) => {
        let {user} = this.state;
        if(user) {
            LikeSDK.like(this.videosRef)
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.danger(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            this.showLoginDependency();
        }
    }

    togglePopover(toState) {
        this.state.popoverIsOpen = typeof toState === "boolean" ? toState : !this.state.popoverIsOpen
        this.updateState();
    }

    onShare = () => {
        this.togglePopover();
    };

    getShareLayout = () => {
        let shareUrl = shareBaseUrl + '/feminine/videos/' + this.props.match.params.key;
        let shareMessage = this.state.videos.title + '\n' + shareUrl;
        return (
            <div className="d-flex flex-row">
                <FacebookShareButton url={shareUrl} quote={shareMessage}>
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareMessage}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={shareMessage}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <TumblrShareButton url={shareUrl} title={shareMessage}>
                    <TumblrIcon size={32} round={true} />
                </TumblrShareButton>
                <EmailShareButton url={shareUrl} body={shareMessage}>
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </div>
        );
    }

    render() {
        let {user ,videos, pageloading} = this.state;
        if(pageloading) {
            return (
                <div className="text-center p-5">
                    <span className="spinner-border text-success" role="status"></span>
                </div>
            );
        }
        let likedColor = user && videos.likedby.includes(user.uid) ? 'liked' : '' ;
        return (
            <div className="site-content">
			    <div className="container">
		            <div className="card">		
                        <div className="product__img">
                            <Player
                                src={videos.url}
                                poster={videos.poster}></Player>
                        </div>
                        <div className="product__content card__content">
                            <header className="product__header d-flex flex-row">
                                <div className="mr-auto">
                                    <button type="button" className={'btn btn-default btn-single-icon product__wish btn-circle btn_center mr-3 ' + likedColor} data-toggle="tooltip" data-placement="top" title={ar.likes} onClick={this.like.bind(this, videos)}>
                                        <i className="icon-heart"></i>
                                    </button>
                                    <Popover 
                                        place={this.state.place} 
                                        isOpen={this.state.popoverIsOpen}
                                        onOuterAction={this.togglePopover.bind(this,false)}
                                        body={this.getShareLayout()}
                                    >
                                    <button type="button" className="btn btn-default btn-single-icon product__wish btn-circle btn_center" data-toggle="tooltip" data-placement="top" title={ar.share} onClick={this.onShare}>
                                        <i className="icon-share"></i>
                                    </button>
                                    </Popover>
                                </div>
                                <div className="">{videos.title}</div>
                            </header>
                            <div>
                                <p className="mb-0">{videos.likes || 0} {ar.likes}  - {moment(videos.timestamp).format('ll')}</p>
                            </div>
                        </div>       
                    </div>
                </div>
            </div>
        );
    }
}