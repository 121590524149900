import React from 'react'
import ParentComponent from '../ParentComponent';
import { AuthContext } from '../AuthContext';
import { Player } from 'video-react';
import { toast } from 'react-toastify';
import {voteUrl} from '../../constants';
import {auth} from '../../lib/firebase';
import ArabicNumbers from '../ArabicNumbers';
import ar from '../../locale';

export default class Poll extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            token: null,
        };
    }

    componentDidMount() {
        let {user} = this.state;
        if(user) {
            auth.currentUser.getIdToken().then(token => {
                this.state.token = token;
                this.updateState();
            });
        }
    }

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    };

    onVote = (option, index, e) => {
        let {user} = this.state;
        if(user) {
            const {path, pollkey} = this.props;
            fetch(voteUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.state.token,
            },
            body: JSON.stringify({
                path: path,
                key: pollkey,
                option: index,
                }),
            })
            .then(response => response.json())
            .then(responseJson => {
                this.updateState();
            })
            .catch(error => {
                toast.error(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
                this.updateState();
            });
        } else {
            this.showLoginDependency();
            if (e && e.target) {
                e.target.checked = false;
            }
        }
    };

    renderOption = (option, index, key) => {
        let {user} = this.state;
        let {closed} = this.props;
        let voted_by = option.voted_by || [];
        let checked = user && voted_by.includes(user.uid);
        let percent = option.votes === 0 ? 0 : ((option.votes / this.props.votes) * 100).toFixed(1);
        let widthPercent = percent;
        if(widthPercent < 1.0){
            widthPercent = 1;
        }
        return(
            <div key={key + index} className=" font_14 d-flex flex-row-reverse align-items-center p-2 text-white">
                {closed && (
                    <input type="radio" value={index} name={key} className="ml-2" defaultChecked={checked} disabled={true} /> 
                )}
                {!closed && (
                    <input type="radio" value={index} name={key} className="ml-2" defaultChecked={checked} onClick={this.onVote.bind(this, option, index)}/> 
                )}
                <div className="flex_1 pr-1 position-relative z99">
                    <span className="position-relative">{option.name}</span>
                    <div className="position-absolute pollabs h-100" style={{backgroundColor: option.color, width: widthPercent+'%'}}></div>
                </div>
                <p className="mb-0 pl-2 pr-2">{ArabicNumbers(percent)} %</p> 
            </div>
        );
    }

    render() {
        let {title, content, poster, options, video, image, pollkey, closed} = this.props;
        return(
            <li className="product__item card">
                <div className="posts posts--cards post-grid post-grid--masonry row">
                    <div className="post-grid__item w-100">
                        <div className="posts__item posts__item--card posts__item--category-1 p-3 m-0">
                            <header className="d-flex flex-row-reverse mb-1">
                                <div className="post-author d-flex flex-row-reverse ml-auto w-100">
                                    <div className="post-author__info w-100">
                                        <h3 className="post-author__name border_bottom font_14 pb-2 text-white">{title}</h3>
                                        {content && (
                                            <div className="posts__date no-pmb mb-2" dangerouslySetInnerHTML={{ __html: content }}></div>
                                        )}
                                    </div>
                                </div>
                            </header>
                            <div>
                                {video && (
                                    <Player
                                        fluid={false}
                                        height={250}
                                        width="100%"
                                        src={video}
                                        poster={poster}
                                    ></Player>
                                )}
                                {image && (
                                    <img src={image} alt="poll" />
                                )}
                            </div>
                            {closed && (
                                <div className="mt-2">
                                    {ar.voting_closed}
                                </div>
                            )}
                            <div className="pb-0 pt-2">
                               {options.map((option, i) => this.renderOption( option,i, pollkey))}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}