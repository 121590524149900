import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/messaging";

const config = {
    apiKey: "AIzaSyDi686kOAxYJgY2kMYkYmTIucuvzunGXW8",
    authDomain: "egyptiancoach-6a357.firebaseapp.com",
    databaseURL: "https://egyptiancoach-6a357.firebaseio.com",
    projectId: "egyptiancoach-6a357",
    storageBucket: "egyptiancoach-6a357.appspot.com",
    messagingSenderId: "309200165502",
    appId: "1:309200165502:web:66ddf435a548c7ec"
}
firebase.initializeApp(config);
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BA22MfmsomMFxiVh_GqV_jerXo2-2quO2P0hvu3t8JcupFskIlFe8ZJreCrVgjWioPF8IVqyAVZU_yL3PDGxq90"
  );
}
export {messaging};
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const appleProvider = new firebase.auth.OAuthProvider('apple.com');
export const auth = firebase.auth();
export default firebase;