export const modalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 10,
  }
};