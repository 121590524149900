import React from 'react';
import ar from '../../locale';
import {
  NavLink
} from "react-router-dom";

export default class NotFound extends React.Component {
  render() {
    return (
      <div className="error-404">
        <div className="row">
          <div className="col-md-8 offset-md-2 text-center">
            <figure className="error-404__figure">
              <img src="assets/images/icon-ghost.svg" alt="OOOOPS" width="200" />
            </figure>
            <header className="error__header">
              <h2 className="error__title text-center">{ar.oops_page_not_found}</h2>
            </header>
            <div className="error__description text-center">
              {ar.not_found_content} <a href="mailto:info@alchemists.com">info@egyptiancoach.com</a>
            </div>
            <footer className="error__cta text-center">
              <NavLink to="/" className="btn btn-primary-inverse">{ar.return_to_home}</NavLink>
            </footer>
          </div>
        </div>
      </div>
    )
  }
}