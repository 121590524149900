import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import News from './news';
import Video from './video';
import Training from './training';
import ar from '../../locale';

export default class Feminine extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
        };
        
    }

    componentDidMount() {}

    componentWillUnmount() {}

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    render() {
        return (
        <>
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <h1 className="page-heading__title"><span className="highlight">{ar.womens_ball}</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-content">
                <div className="container">
                    <Tabs>
                        <TabList className="react-tabs__tab-listc d-flex flex_1 flex-row-reverse justify-content-between p-0">
                            <Tab className="react-tabs__tabc flex_1">{ar.videos}</Tab>
                            <Tab className="react-tabs__tabc flex_1">{ar.news}</Tab>
                            <Tab className="react-tabs__tabc flex_1">{ar.training}</Tab>
                        </TabList>

                        <TabPanel>
                            <Video {...this.props} />
                        </TabPanel>
                        <TabPanel>
                            <News {...this.props} />
                        </TabPanel>
                        <TabPanel>
                            <Training {...this.props} />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
        );
    }
}