import React from 'react'
import ParentComponent from '../../lib/ParentComponent';
import { AuthContext } from '../../lib/AuthContext';
import Utils from '../../lib/Utils';
import firebase from '../../lib/firebase';
import {shareBaseUrl} from '../../constants';
import FeminineVideo from '../../lib/FeminineVideo';
import LikeSDK from '../../lib/LikeSDK';
import { toast } from 'react-toastify';
import ar from '../../locale';

export default class Video extends ParentComponent {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: context.user,
            videos: [],
            pageloading: true,
        };
        this.videosRef = firebase.database().ref('videos/');        
    }

    componentDidMount() {
        this.videosRef.on('value', response => {
            this.state.videos = Utils.convertToArrayWithInsertionOrder(response.val());
            this.state.pageloading = false;
            this.updateState();
        });
    }

    componentWillUnmount() {
        this.videosRef.off('value');
    }

    updateState = () => {
        this.setState(prev => {
            return {...prev}
        })
    }

    showLoginDependency = () => {
        toast.error(ar.login_to_use,{
            position: toast.POSITION.TOP_CENTER
        });
    }

    loadDetail = (key) => {
        this.props.history.push('/feminine/videos/' + key);
    }

    like = (videos) => {
        let {user} = this.state;
        if(user) {
            LikeSDK.like(this.videosRef.child(videos.key))
            .then(response => {
                //Don't do anything, UI will be updated by on value listener
            })
            .catch(error => {
                toast.danger(error.message,{
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } else {
            this.showLoginDependency();
        }
    }

    renderVideosView = (videos) => {
        let shareUrl = shareBaseUrl + '/feminine/videos/' + videos.key;
        let shareMessage = videos.title + '\n';
        return(
            <FeminineVideo 
                shareUrl={shareUrl}
                shareMessage={shareMessage}
                timestamp={videos.timestamp}
                title={videos.title}
                poster={videos.poster}
                video={videos.url}
                likes={videos.likes || 0}
                likedby={videos.likedby || []}
                shares={videos.shares || 0}
                url={videos.url}
                key={videos.key}
                onLike={this.like.bind(this, videos)}
            />
        );
    }

    render() {
        return (
            <>
                <div className="page-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <h1 className="page-heading__title"><span className="highlight">{ar.womens_ball}</span></h1>
                                <h4 className="mt-1 text-center">{ar.videos}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-content">
                    <div className="container">
                        {this.state.pageloading && (
                            <div className="text-center">
                                <span className="spinner-border text-success" role="status"></span>
                            </div>
                        )}
                        {!this.state.pageloading && (
                            <ul className="products products--grid products--grid-3 products--grid-overlay flex-row-reverse">
                                {this.state.videos.map(this.renderVideosView)}   
                            </ul>
                        )}
                    </div>
                </div>
            </>
        );
    }
}