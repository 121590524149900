import {auth} from '../firebase';
/**
 * Likes of unlikes any firebase end point.
 */
const LikeSDK = {
  like: firebaseRef => {
    let uid = auth.currentUser.uid;
    return firebaseRef.child('likedby').transaction(likedby => {
      likedby = likedby || [];
      if (!likedby.includes(uid)) {
        likedby.push(uid);
      } else {
        const userIndex = likedby.findIndex(i => i === uid);
        likedby.splice(userIndex, 1);
      }
      firebaseRef.update({
        likes: likedby.length,
      });
      return likedby;
    });
  },
};

export default LikeSDK;
